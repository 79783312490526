import { useState } from "react";
import Header from "../../components/content_wrapper/header";
import CustomLaoder from "../../components/customLoader";
import NoRecordFound from "../../components/noRecordFound";
import { useGetHostRequests } from "../../hooks/hostRequestsApi/getHostRequests";
import Pagination from "../../utils/pagination";
import moment from "moment";

const HostRequests = () => {
  const [countData, setCountData] = useState<any>(5);
  const [pageNumber, setPageNumber] = useState<any>(1);
  const [searchValue, setSearchValue] = useState<string>("");
  const [finalValue, setFinalValue] = useState<string>("");

  const { data, isLoading } = useGetHostRequests({
    page: pageNumber,
    limit: countData,
    search: finalValue,
  });

  const handlePrevPage = () => {
    setPageNumber((prevPage: any) => prevPage - 1);
  };

  const handleNextPage = () => {
    setPageNumber((nextPage: any) => nextPage + 1);
  };
  const handleRowLimitChange = (newValue: number) => {
    setCountData(newValue);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };
  const handleApplyClick = () => {
    setFinalValue(searchValue);
  };

  const handleCancelClick = () => {
    setFinalValue("");
    setSearchValue("");
  };

  return (
    <div className="main-wrapper">
      <Header title="Host Requests" />
      <CustomLaoder loader={isLoading}>
        <div className="content-wrapper">
          <section className="main-section spacer">
            <div className="container">
              <div className="col-xl-12">
                <form>
                  <div className="row justify-content-between g-3">
                    <div className="col-lg-8 col-xl-8 col-xxl-6">
                      <div className="row align-items-center gy-3">
                        <div className="col-sm-7 col-xl-7">
                          <div className="form-block p-relative">
                            <input
                              type="search"
                              className="form-control"
                              id="search"
                              placeholder="Search by Email"
                              value={searchValue}
                              onChange={handleSearchChange}
                            />
                            <span className="search-icon" />
                          </div>
                        </div>
                        <div className="col-12 col-sm-5 col-xl-5">
                          <button
                            className="btn primary-btn"
                            onClick={handleApplyClick}>
                            Apply
                          </button>
                          <button
                            className="btn secondary-btn"
                            onClick={handleCancelClick}>
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              {data?.data?.hostRequests?.map((item: any) => (
                <div className="wrapper-querycard mt-4" key={item._id}>
                  <div className="row gy-3">
                    <div className="col-xl-12 mt-0">
                      <div className="tab-content-wrapper">
                        <div className="d-flex justify-content-between align-items-center text-capitalize">
                          <h2>{item?.host_name}</h2>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div>
                        <div>
                          <i className="fa-solid fa-phone" />
                          <span className="mt-0 m-lg-2">{`(${item?.country_code}) ${item?.phone_number}`}</span>
                        </div>
                        <div>
                          <i className="fa-solid fa-at" />
                          <span className="mt-0 m-lg-2">
                            {item?.host_email}
                          </span>
                        </div>
                        <div>
                          <i className="fa-solid fa-location-dot" />
                          <span className="mt-0 m-lg-2">{item?.address}</span>
                        </div>
                      </div>

                      <div className="d-flex flex-column">
                        <div>
                          <i className="fa-solid fa-store" />
                          <span className="mt-0 m-lg-2">
                            {item?.store_name}
                          </span>
                        </div>
                        <div>
                          <i className="fa-regular fa-envelope" />
                          <span className="mt-0 m-lg-2">
                            {item?.user_email}
                          </span>
                        </div>
                        <div>
                          <i className="fa-regular fa-calendar" />
                          <span className="mt-0 m-lg-2">
                            {moment.utc(item?.createdAt).format("ll")}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {data?.status === false && <NoRecordFound />}
              {data?.status !== false && data?.data?.totalRecords > 1 && (
                <Pagination
                  totalPages={data?.data?.totalPages}
                  currentPage={pageNumber}
                  handlePrevPage={handlePrevPage}
                  handleNextPage={handleNextPage}
                  handleRowLimitChange={handleRowLimitChange}
                />
              )}
            </div>
          </section>
        </div>
      </CustomLaoder>
    </div>
  );
};

export default HostRequests;
