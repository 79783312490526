import React, { Fragment } from "react";
import Multiselect from "multiselect-react-dropdown";
import { TimeSlot } from "./keyHostListing";
import CountrySelect from "../../utils/countryCode";
import en from "react-phone-number-input/locale/en.json";
import { CountryDropdown } from "react-country-region-selector";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";

type Props = {
  values: any;
  setFieldValue: any;
  handleChange: (e: any) => void;
  handleBlur: any;
  touched: any;
  errors: any;
  country: any;
  handleCountryChange: (e: any) => void;
  handelPhoneNumber: (e: any) => void;
  timeSlots: any;
  setTimeSlots: any;
  updateTimeSlot: any;
  handleSubmit: any;
  BoxItems: any;
  selectedOptions: any;
  onSelected: any;
  isOpen: boolean | any;
  handleModalClose: () => void;
  modalTitle: string;
  onRemove: any;
  handleErrorClick: () => void;
  errorMessage: string;
  timeError: boolean;
};

const KeyHostModal = ({
  setFieldValue,
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  country,
  handleCountryChange,
  handelPhoneNumber,
  timeSlots,
  handleSubmit,
  updateTimeSlot,
  BoxItems,
  selectedOptions,
  onSelected,
  onRemove,
  isOpen,
  handleModalClose,
  modalTitle,
  handleErrorClick,
  errorMessage,
  timeError,
}: Props) => {
  const handleselectCountry = (val: any) => {
    setFieldValue("country", val);
  };
  if (!isOpen) {
    return null;
  } else {
    return (
      <Fragment>
        <div
          style={{ backgroundColor: "#0006" }}
          className="modal fade common-modal addkeyhost-modal show d-block"
          id="addKeyHost"
          tabIndex={-1}
          aria-labelledby="addKeyHostLabel"
          aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleModalClose}
                />
                <div className="common-box-body">
                  <h2>{modalTitle}</h2>
                  <div className="common-modal-form">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="common-modal-field">
                          <label>Username</label>
                          <input
                            value={values.username}
                            name="username"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="text"
                            className="form-control"
                            placeholder="Enter Username"
                          />
                          {touched.username && errors.username && (
                            <p className="error">{errors.username}</p>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="common-modal-field">
                          <label>Email</label>
                          <input
                            type="text"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                            placeholder="Enter Email"
                          />
                          {touched.email && errors.email && (
                            <p className="error">{errors.email}</p>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="common-modal-field">
                              <label>Country Code</label>
                              <CountrySelect
                                className="form-select"
                                name="countryCode"
                                labels={en}
                                value={country}
                                onChange={handleCountryChange}
                                onblur={handleBlur}
                              />
                              {touched.countryCode && errors.countryCode && (
                                <p className="error">{errors.countryCode}</p>
                              )}
                              {/* <PhoneInput
                                className="phone-input form-control"
                                country={country}
                                limitMaxLength={true}
                                onCountryChange={handleCountryChange}
                                placeholder="Enter phone number"
                                value={values.phoneNumber}
                                onChange={handelPhoneNumber}
                                onBlur={handleBlur}
                              />
                              {touched.countryCode && errors.countryCode && (
                                <p className="error">
                                  {errors.countryCode}
                                </p>
                              )}
                              {touched.phoneNumber && errors.phoneNumber && (
                                <p className="error">
                                  {errors.phoneNumber}
                                </p>
                              )} */}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="common-modal-field">
                              <label>Phone Number</label>
                              <input
                                className="phone-input form-control"
                                type="tel"
                                placeholder="Enter phone number"
                                minLength={8}
                                maxLength={10}
                                name="phoneNumber"
                                onChange={handleChange}
                                value={values.phoneNumber}
                                onBlur={handleBlur}
                              />
                              {touched.phoneNumber && errors.phoneNumber && (
                                <p className="error">{errors.phoneNumber}</p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="common-modal-field">
                          <label>Address</label>
                          <input
                            type="text"
                            name="address"
                            value={values.address}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                            className="form-control"
                            placeholder="Enter Address"
                          />
                          {touched.address && errors.address && (
                            <p className="error">{errors.address}</p>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="common-modal-field">
                              <label>Latitude</label>
                              <input
                                name="lat"
                                value={values.lat}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="number"
                                className="form-control"
                                placeholder="Enter latitide"
                              />
                              {touched.lat && errors.lat && (
                                <p className="error">{errors.lat}</p>
                              )}
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="common-modal-field">
                              <label>Longitude</label>
                              <input
                                name="lng"
                                value={values.lng}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="number"
                                className="form-control"
                                placeholder="Enter longitute"
                              />
                              {touched.lng && errors.lng && (
                                <p className="error">{errors.lng}</p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="common-modal-field">
                              <label>Select Country</label>
                              <div className="form-control">
                                <CountryDropdown
                                  name="country"
                                  onBlur={handleBlur}
                                  value={values.country}
                                  onChange={handleselectCountry}
                                />
                              </div>
                              {touched.country && errors.country && (
                                <p className="error">{errors.country}</p>
                              )}
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="common-modal-field">
                              <label>Store name</label>
                              <input
                                type="text"
                                name="store_name"
                                value={values.store_name}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                onBlur={handleBlur}
                                className="form-control"
                                placeholder="Enter Store name"
                              />
                              {touched.store_name && errors.store_name && (
                                <p className="error">{errors.store_name}</p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="common-modal-field">
                          <div className="time-blk">
                            <div className="time-title-blk">
                              <span className="time-title">From (AM)</span>
                              <span className="time-title">To (PM)</span>
                            </div>
                            <div className="time-wrapper" onBlur={handleBlur}>
                              {timeSlots?.map((slot: TimeSlot, index: any) => (
                                <div className="time-wrapper-list" key={index}>
                                  <span className="day-title">{slot?.day}</span>
                                  <div className="time-field-blk">
                                    <TimePicker
                                      hourPlaceholder="hh"
                                      minutePlaceholder="mm"
                                      clockIcon={false}
                                      openClockOnFocus={false}
                                      className="form-control"
                                      disabled={slot?.closed}
                                      onChange={(e) =>
                                        updateTimeSlot(index, "start_time", e)
                                      }
                                      value={slot?.start_time}
                                    />

                                    {/* <input
                                      className="form-control"
                                      type="time"
                                      value={slot.start_time}
                                      disabled={slot.closed}
                                      onChange={(event) =>
                                        updateTimeSlot(
                                          index,
                                          "start_time",
                                          event.target.value
                                        )
                                      }
                                    /> */}
                                    <TimePicker
                                      hourPlaceholder="hh"
                                      minutePlaceholder="mm"
                                      clockIcon={false}
                                      openClockOnFocus={false}
                                      className="form-control"
                                      disabled={slot?.closed}
                                      onChange={(e) =>
                                        updateTimeSlot(index, "end_time", e)
                                      }
                                      value={slot?.end_time}
                                    />

                                    {/* <input
                                      className="form-control"
                                      type="time"
                                      value={slot.end_time}
                                      disabled={slot.closed}
                                      onChange={(event) =>
                                        updateTimeSlot(
                                          index,
                                          "end_time",
                                          event.target.value
                                        )
                                      }
                                    /> */}
                                  </div>
                                  <div className="closed-check-blk">
                                    <input
                                      type="checkbox"
                                      id={index}
                                      checked={slot?.closed}
                                      onChange={(event) =>
                                        updateTimeSlot(
                                          index,
                                          "closed",
                                          event.target.checked
                                        )
                                      }
                                    />
                                    <label htmlFor={index}>
                                      <span className="check-box" />
                                      <span className="checklabel">Closed</span>
                                    </label>
                                  </div>
                                </div>
                              ))}
                            </div>
                            {timeError === true ? (
                              <p className="error">{errorMessage}</p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="common-modal-field">
                          <label>Select box</label>
                          <Multiselect
                            placeholder="Select boxes"
                            className="multisel form-select"
                            displayValue="box_id"
                            hideSelectedList
                            showCheckbox
                            emptyRecordMsg="No Options Available"
                            selectedValues={selectedOptions}
                            onSelect={onSelected}
                            onRemove={onSelected}
                            options={BoxItems}
                          />
                          {touched.selectedOption && errors.selectedOption && (
                            <p className="error">{errors.selectedOption}</p>
                          )}
                        </div>
                        <div className="common-modal-field">
                          <label>Store info:</label>
                          <textarea
                            name="store_info"
                            value={values.store_info}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                            placeholder="Enter Store info"
                          />
                          {touched.store_info && errors.store_info && (
                            <p className="error">{errors.store_info}</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="common-modal-btns">
                    <button
                      type="button"
                      className="btn primary-btn"
                      onClick={(e) => {
                        handleSubmit(e);
                        handleErrorClick();
                      }}>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
};

export default KeyHostModal;
