import React, { useEffect, useState } from "react";
import { EyeSvg } from "../../utils/svgComponent";
import Header from "../../components/content_wrapper/header";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import {
  addBoxValidationSchema,
  addBoxinitialValues,
} from "../../validations/boxManagementValidation";
import { toast } from "react-toastify";
import { useAddBox } from "../../hooks/boxManagementApi/addBox";
import { useGetBoxList } from "../../hooks/boxManagementApi/getBoxList";
import Pagination from "../../utils/pagination";
import { useUpdateBoxStatus } from "../../hooks/boxManagementApi/updateStatus";
import { useUpdateBox } from "../../hooks/boxManagementApi/updateBox";
import CustomLaoder from "../../components/customLoader";

const Box_management_listing = () => {
  const [pageNumber, setPageNumber] = useState<any>(1);
  const [countData, setCountData] = useState<any>(20);
  const [searchValue, setSearchValue] = useState<string>("");
  const [finalValue, setFinalValue] = useState<string>("");
  const [modalData, setModalData] = useState({
    open: false,
    modalTitle: "",
    modalType: "",
    id: "",
    usedCubes: "",
  });

  const { data: boxData, isLoading } = useGetBoxList({
    limit: countData,
    page: pageNumber,
    search: finalValue,
  });
  const handlePrevPage = () => {
    setPageNumber((prevPage: any) => prevPage - 1);
  };

  const handleNextPage = () => {
    setPageNumber((nextPage: any) => nextPage + 1);
  };
  const handleRowLimitChange = (newValue: number) => {
    setCountData(newValue);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };
  const handleApplyClick = () => {
    setFinalValue(searchValue);
  };

  const handleCancelClick = () => {
    setFinalValue("");
    setSearchValue("");
  };

  const navigate = useNavigate();

  const handleClick = (id: any) => {
    navigate("/boxmanagementdetail", {
      state: {
        id,
      },
    });
  };

  // Add Box
  const { mutateAsync } = useAddBox();

  // Update Box
  const { mutateAsync: updateBoxMutation } = useUpdateBox();

  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
    resetForm,
  } = useFormik({
    initialValues: addBoxinitialValues,
    validationSchema: addBoxValidationSchema,
    onSubmit: async () => {
      if (modalData.modalType === "Add") {
        try {
          const res: any = await mutateAsync({
            box_id: values.box_id,
            total_keys: values.total_keys,
          });
          if (res?.status === true) {
            toast.success(res?.message);
            resetForm();
            setModalData({ ...modalData, open: false });
          }
        } catch (error: any) {
          toast.error(error?.response?.data?.message);
        }
      }
      if (modalData.modalType === "Edit") {
        try {
          const res: any = await updateBoxMutation({
            id: modalData.id,
            box_id: values.box_id,
            total_keys: values.total_keys,
          });
          if (res?.status === true) {
            toast.success(res?.message);
            resetForm();
            setModalData({ ...modalData, open: false });
          }
        } catch (error: any) {
          toast.error(error?.response?.data?.message);
        }
      } else {
        return null;
      }
    },
  });

  //Status update
  const { mutateAsync: update } = useUpdateBoxStatus();
  const handleToggleClick = async (id: string, action: number) => {
    let Status = action === 1 ? 0 : 1;
    try {
      await update({
        id: id,
        status: Status,
      });
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };

  //return child
  const returnChild = () => {
    if (!modalData.open) {
      return null;
    } else
      return (
        <div
          style={{ backgroundColor: "#0006" }}
          className="modal fade common-modal show d-block"
          tabIndex={-1}
          aria-labelledby="addBoxDetailLabel"
          aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleCloseModal}
                />
                <div className="common-box-body">
                  <h2>{modalData.modalTitle}</h2>
                  <div className="common-modal-form">
                    <div className="common-modal-field">
                      <label>Box ID</label>
                      <input
                        disabled={modalData?.id ? true : false}
                        type="text"
                        className="form-control"
                        placeholder="Enter Box ID"
                        name="box_id"
                        value={values.box_id}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched.box_id && errors.box_id && (
                        <p className="error">{errors.box_id}</p>
                      )}
                    </div>
                    <div className="common-modal-field">
                      <label>Total number of keys/Cubicles</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Enter keys/Cubicles"
                        name="total_keys"
                        onChange={handleChange}
                        value={values.total_keys}
                        onBlur={handleBlur}
                      />
                      {touched.total_keys && errors.total_keys && (
                        <p className="error">{errors.total_keys}</p>
                      )}
                    </div>
                  </div>
                  <div className="common-modal-btns">
                    <button
                      className="btn primary-btn"
                      onClick={() => handleSubmit()}>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
  };

  const handleModalOpen = (e: any, data?: any) => {
    if (e.target.title === "Add") {
      setModalData({
        ...modalData,
        modalTitle: "Add Box Detail",
        open: true,
        modalType: e.target.title,
        id: "",
        usedCubes: "",
      });
    }
    if (e.target.title === "Edit") {
      setModalData({
        ...modalData,
        modalTitle: "Edit Box Detail",
        open: true,
        modalType: e.target.title,
        id: data?._id,
        usedCubes: data?.used_cubicales,
      });
      setValues({
        box_id: data?.box_id,
        total_keys: data?.total_cubicales,
      });
    } else return null;
  };

  const handleCloseModal = () => {
    setModalData({
      ...modalData,
      modalTitle: "",
      open: false,
    });
    resetForm();
  };

  return (
    <div className="main-wrapper">
      <Header title="Boxes" />
      <CustomLaoder loader={isLoading}>
        <div className="content-wrapper">
          <section className="main-section coach-section spacer">
            <div className="container">
              <div className="wrapper-card">
                <div className="row gy-3">
                  <div className="col-xl-12">
                    <form>
                      <div className="row justify-content-between g-3">
                        <div className="col-lg-8 col-xl-8 col-xxl-6">
                          <div className="row align-items-center gy-3">
                            <div className="col-sm-7 col-xl-7">
                              <div className="form-block p-relative">
                                <input
                                  type="search"
                                  className="form-control"
                                  id="search"
                                  placeholder="Search Box ID"
                                  value={searchValue}
                                  onChange={handleSearchChange}
                                />
                                <span className="search-icon" />
                              </div>
                            </div>
                            <div className="col-12 col-sm-5 col-xl-5">
                              <button
                                type="submit"
                                className="btn primary-btn"
                                onClick={handleApplyClick}>
                                Apply
                              </button>
                              <button
                                type="submit"
                                className="btn secondary-btn"
                                onClick={handleCancelClick}>
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="col-xl-12">
                    <div className="sub-heading flex-box">
                      <h2>Box Listing</h2>
                      <button
                        title="Add"
                        onClick={(e) => handleModalOpen(e)}
                        className="btn primary-btn me-0">
                        + Add Box
                      </button>
                    </div>
                    <div className="table-blk">
                      <table id="myTable" className="display border-0">
                        <thead>
                          <tr>
                            <th>Box ID</th>
                            <th>Total cubicles</th>
                            <th>Login ID</th>
                            <th>Password</th>
                            <th>Assigned key host</th>
                            {/* <th>Location</th> */}
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {boxData?.data?.allBoxes?.map(
                            (data: any, index: number) => (
                              <tr key={index}>
                                <td>{data?.box_id}</td>
                                <td>{data?.total_cubicales}</td>
                                <td>{data?.login_id}</td>
                                <td>{data?.password}</td>
                                <td>{data?.assigned_key_host}</td>
                                {/* <td>{data?.location}</td> */}
                                <td>
                                  <span
                                    // className={
                                    //   data?.status === 1
                                    //     ? "table-status st-active"
                                    //     : "table-status st-suspended"
                                    // }
                                    className={`c-pill 
                                    ${
                                      data?.status === 1
                                        ? "c-pill--success"
                                        : "c-pill--danger"
                                    }`}>
                                    {data?.status === 1
                                      ? "Active"
                                      : "Suspended"}
                                  </span>
                                </td>
                                <td>
                                  <span className="table-action">
                                    <a
                                      onClick={() => handleClick(data._id)}
                                      className="table-eye">
                                      <EyeSvg />
                                    </a>
                                    <span
                                      className="table-check"
                                      key={data._id}>
                                      <input
                                        type="checkbox"
                                        id={`toggleEye${data._id}`}
                                        onChange={() =>
                                          handleToggleClick(
                                            data._id,
                                            data?.status
                                          )
                                        }
                                        checked={
                                          data?.status === 1 ? true : false
                                        }
                                      />
                                      <label htmlFor={`toggleEye${data._id}`} />
                                    </span>
                                    <img
                                      title="Edit"
                                      className="table-edit"
                                      // data-bs-toggle="modal"
                                      // data-bs-target="#updateBoxDetail"
                                      onClick={(e) => {
                                        // handleEdit(data._id, data);
                                        handleModalOpen(e, data);
                                      }}
                                      src="/assets/images/icons/edit_icon.svg"
                                    />
                                  </span>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                      {boxData?.data?.allBoxes?.length === 0 ? (
                        <div className="d-flex justify-content-center align-items-center my-3 sub-heading">
                          <h2 className="m-0">No data found</h2>
                        </div>
                      ) : null}
                      {isLoading && (
                        <div className="d-flex justify-content-center align-items-center my-3 sub-heading">
                          <h2 className="m-0">Loading..</h2>
                        </div>
                      )}
                    </div>
                    <Pagination
                      totalPages={boxData?.data?.totalPage}
                      currentPage={pageNumber}
                      handlePrevPage={handlePrevPage}
                      handleNextPage={handleNextPage}
                      handleRowLimitChange={handleRowLimitChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        {returnChild()}
      </CustomLaoder>
    </div>
  );
};

export default Box_management_listing;
