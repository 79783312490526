import * as Yup from "yup";
interface FormValues {
  newPassword: string;
  confirmPassword: string;
}
//initial values
const LogininitialValues = {
  email: "",
  password: "",
};

//  validations schemas
const loginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Invalid email address"
    )
    .required("Email is required*"),
  password: Yup.string().required("Password is required*"),
});

// admin Change paasword
const ChangePasswordinitialValues = {
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
};
const ChangePasswordInitialSchema = Yup.object().shape({
  oldPassword: Yup.string().required("Old Password is required*"),
  newPassword: Yup.string()
    .required("Password is required")
    .min(5, "Your password is too short.")
    .matches(/[a-zA-Z]/, "Password can only contain Latin letters."),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("newPassword")],
    "Passwords must match"
  ),
});

const editOwnerInitialValues = {
  username: "",
  phoneNumber: "",
  countryCode: "",
};

const editOwnerValidationSchema = Yup.object().shape({
  username: Yup.string()
    .required("Username is required")
    .min(4, "Username must be at least 6 characters")
    .max(20, "Username must not exceed 20 characters")
    .matches(
      /^(?! )[a-zA-Z0-9_ -]{4,20}$/,
      "Username can start with a letter, number, or underscore, and can contain letters, numbers, spaces, and underscores"
    ),
  phoneNumber: Yup.string()
    .required("Phone number is required")
    .matches(/^\d+$/, "Phone number must be digits only"),
  countryCode: Yup.number().required("Country code is required*"),
});

const editProfileValues = {
  name: "",
};
const editProfileValuesSchema = Yup.object().shape({
  name: Yup.string().required("Name is required*"),
});

const addCubeValue = {
  cubeNumber: "",
};
const addCubeValidationSchema = Yup.object().shape({
  cubeNumber: Yup.number().required("Cube number is require"),
});

//update content
//update content management
const contentValues = {
  description: "",
};
const contentValuesSchema = Yup.object().shape({
  description: Yup.string()
    .required("Description is required*")
    .min(15, "Description should have atleast 15 characters"),
});

// reset password

const resetPasswordValues: FormValues = {
  newPassword: "",
  confirmPassword: "",
};
const resetPasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required("Password is required")
    .min(5, "Your password is too short.")
    .matches(/[a-zA-Z]/, "Password can only contain Latin letters."),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("newPassword")],
    "Passwords must match"
  ),
});
// query Answer schema
const queryAnswerValues = {
  answer: "",
};
const queryAnswerSchema = Yup.object().shape({
  answer: Yup.string().required("Answer is required*").trim(),
});

const keyHostChangePasswordValues = {
  email: "",
  password: "",
  confirmPassword: "",
};
const keyHostChangePasswordSchema = Yup.object().shape({
  email: Yup.string().required("Email is required"),
  password: Yup.string()
    .required("Password is required*")
    .matches(/[a-zA-Z]/, "Password can only contain Latin letters."),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password")],
    "Password must match"
  ),
});
const stripeValues = {
  key: "",
  publicKey: "",
};
const stripeSchema = Yup.object().shape({
  key: Yup.string().required("Key is required").trim(),
  publicKey: Yup.string().required("Public Key is required").trim(),
});
export {
  LogininitialValues,
  loginValidationSchema,
  editOwnerInitialValues,
  editOwnerValidationSchema,
  editProfileValues,
  editProfileValuesSchema,
  addCubeValue,
  addCubeValidationSchema,
  contentValues,
  contentValuesSchema,
  resetPasswordValues,
  resetPasswordSchema,
  queryAnswerValues,
  queryAnswerSchema,
  ChangePasswordinitialValues,
  ChangePasswordInitialSchema,
  keyHostChangePasswordValues,
  keyHostChangePasswordSchema,
  stripeValues,
  stripeSchema,
};
