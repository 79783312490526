import React, { useState } from "react";
import Header from "../../components/content_wrapper/header";
import { useGetTransactionLogs } from "../../hooks/transactionManagementApi/getLogs";
import Pagination from "../../utils/pagination";
import moment from "moment";
import { DateRangePicker } from "react-nice-dates";
import { enGB } from "date-fns/locale";
import getSymbolFromCurrency from "currency-symbol-map";
import "react-nice-dates/build/style.css";
import CustomLaoder from "../../components/customLoader";

const TransactionListing: React.FC = () => {
  const [countData, setCountData] = useState<any>(20);
  const [pageNumber, setPageNumber] = useState<any>(1);
  const [searchValue, setSearchValue] = useState<string>("");
  const [finalValue, setFinalValue] = useState<string>("");
  const [startDates, setStartDates] = useState<any>();
  const [endDates, setEndDates] = useState<any>();

  const fromDate = startDates ? moment(startDates).format("YYYY-MM-DD") : "";
  const toDate = endDates ? moment(endDates).format("YYYY-MM-DD") : "";

  const { data, isLoading, isError } = useGetTransactionLogs({
    page: pageNumber,
    limit: countData,
    search: finalValue,
    fromDate: fromDate,
    toDate: toDate,
  });
  const handlePrevPage = () => {
    setPageNumber((prevPage: any) => prevPage - 1);
  };

  const handleNextPage = () => {
    setPageNumber((nextPage: any) => nextPage + 1);
  };
  const handleRowLimitChange = (newValue: number) => {
    setCountData(newValue);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };
  const handleApplyClick = () => {
    setFinalValue(searchValue);
  };

  const handleCancelClick = () => {
    setFinalValue("");
    setSearchValue("");
    setEndDates(null);
    setStartDates(null);
  };

  return (
    <div className="main-wrapper">
      <Header title="Transactions" />
      <CustomLaoder loader={isLoading}>
        <div className="content-wrapper">
          <section className="main-section coach-section spacer">
            <div className="container">
              <div className="wrapper-card">
                <div className="row gy-3">
                  <div className="col-xl-12">
                    <form>
                      <div className="row justify-content-between g-3">
                        <div className="col-xl-12">
                          <div className="sub-heading mb-2 mt-0 p-relative">
                            <h2 className="h4 mb-0">Date range</h2>
                          </div>
                        </div>
                        <div className="col-xl-12 col-xxl-5">
                          <DateRangePicker
                            locale={enGB}
                            startDate={startDates}
                            endDate={endDates}
                            onStartDateChange={setStartDates}
                            onEndDateChange={setEndDates}
                            // minimumDate={new Date()}
                            minimumLength={1}
                            format="dd MMM yyyy">
                            {({
                              startDateInputProps,
                              endDateInputProps,
                              focus,
                            }) => (
                              <div className="d-flex gap-3">
                                <input
                                  className={"form-control"}
                                  {...startDateInputProps}
                                  placeholder="Start date"
                                />

                                <div className="align-content-center">
                                  <i
                                    className="fa-solid fa-chevron-right"
                                    style={{ color: "#72bcb8" }}></i>
                                </div>

                                <input
                                  className={"form-control"}
                                  {...endDateInputProps}
                                  placeholder="End date"
                                />
                              </div>
                            )}
                          </DateRangePicker>
                        </div>
                        <div className="col-lg-8 col-xl-8 col-xxl-6">
                          <div className="row align-items-center gy-3">
                            <div className="col-sm-7 col-xl-6">
                              <div className="form-block p-relative">
                                <input
                                  type="text"
                                  className="form-control"
                                  // id="search"
                                  placeholder="Search by Name or Email"
                                  value={searchValue}
                                  onChange={handleSearchChange}
                                />
                                <span className="search-icon" />
                              </div>
                            </div>
                            <div className="col-12 col-sm-5 col-xl-6">
                              <button
                                type="submit"
                                className="btn primary-btn"
                                onClick={handleApplyClick}>
                                Apply
                              </button>
                              <button
                                type="submit"
                                className="btn secondary-btn"
                                onClick={handleCancelClick}>
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="col-xl-12">
                    <div className="sub-heading">
                      <h2>Transactions list</h2>
                    </div>
                    <div className="table-blk table-responsive pb-2">
                      <table id="myTable" className="display border-0">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Email Address</th>
                            <th>Transaction ID</th>
                            <th>Subscription Plan</th>
                            <th>Actual Amount</th>
                            <th>Paid Amount</th>
                            <th>Tax</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.data?.transactionLogs?.map((items: any) => (
                            <tr>
                              <td>
                                <span className="table-profile">
                                  <span className="table-profileimg">
                                    <img
                                      src="assets/images/icons/dummy-user.png"
                                      alt="user"
                                    />
                                  </span>
                                  <span className="table-username text-capitalize">
                                    {items.name}
                                  </span>
                                </span>
                              </td>
                              <td>{items?.email}</td>
                              <td>{items?.subscriptionId}</td>

                              <td className="text-capitalize">
                                {items?.subscriptionPlan}
                              </td>

                              <td>
                                {getSymbolFromCurrency(items?.planCurrency)}
                                {items?.planFees ? items?.planFees : "0"}
                              </td>
                              <td>
                                {items?.paidAmount === undefined
                                  ? "£0"
                                  : `${getSymbolFromCurrency(
                                      items?.planCurrency
                                    )}${
                                      items?.paidAmount
                                        ? items?.paidAmount
                                        : "0"
                                    } `}
                                {items.coupon !== "" && (
                                  <span
                                    style={{
                                      color: "#72BCB8",
                                      fontWeight: "600",
                                      fontSize: "13px",
                                      padding: "2px 5px 2px 5px",
                                    }}>{`(${items.coupon})`}</span>
                                )}
                              </td>

                              <td>{items.taxPercentage}%</td>
                              <td>{items?.createdAt}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {data?.length === 0 || isError === true ? (
                        <div className="d-flex justify-content-center align-items-center my-3 sub-heading">
                          <h2 className="m-0">No data found</h2>
                        </div>
                      ) : null}

                      <Pagination
                        totalPages={data?.data?.totalPages}
                        currentPage={pageNumber}
                        handlePrevPage={handlePrevPage}
                        handleNextPage={handleNextPage}
                        handleRowLimitChange={handleRowLimitChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </CustomLaoder>
    </div>
  );
};

export default TransactionListing;
