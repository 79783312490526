import React, { useState } from "react";
import Header from "../../components/content_wrapper/header";
import CustomLaoder from "../../components/customLoader";
import Pagination from "../../utils/pagination";
import { useGetReports } from "../../hooks/reportsApi/get_reports";
import { useNavigate } from "react-router-dom";

const Reports: React.FC = () => {
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState<any>(1);
  const [countData, setCountData] = useState<any>(20);
  const [searchValue, setSearchValue] = useState<string>("");
  const [finalValue, setFinalValue] = useState<string>("");

  const { data, isLoading } = useGetReports({
    page: pageNumber,
    limit: countData,
    search: finalValue,
  });
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };
  const handlePrevPage = () => {
    setPageNumber((prevPage: any) => prevPage - 1);
  };

  const handleNextPage = () => {
    setPageNumber((nextPage: any) => nextPage + 1);
  };
  const handleRowLimitChange = (newValue: number) => {
    setCountData(newValue);
  };
  const handleApplyClick = () => {
    setFinalValue(searchValue);
  };

  const handleCancelClick = () => {
    setFinalValue("");
    setSearchValue("");
  };
  const handleClick = (id: string, status: any) => {
    navigate("/keymanagementdetail", {
      state: {
        id,
        status,
      },
    });
  };
  const handleHostClick = (id: any) => {
    navigate("/keyhostdetail", {
      state: {
        id,
      },
    });
  };
  console.log(data?.data.length);

  return (
    <div className="main-wrapper">
      <Header title="Reports" />
      <CustomLaoder loader={isLoading}>
        <div className="content-wrapper">
          <section className="main-section coach-section spacer">
            <div className="container">
              <div className="wrapper-card">
                <div className="row gy-3">
                  <div className="col-xl-12">
                    <form>
                      <div className="row justify-content-between g-3">
                        <div className="col-lg-8 col-xl-8 col-xxl-6">
                          <div className="row align-items-center gy-3">
                            <div className="col-sm-7 col-xl-7">
                              <div className="form-block p-relative">
                                <input
                                  type="search"
                                  className="form-control"
                                  id="search"
                                  placeholder="Search by Key Name or Key Host Name"
                                  value={searchValue}
                                  onChange={handleSearchChange}
                                />
                                <span className="search-icon" />
                              </div>
                            </div>
                            <div className="col-12 col-sm-5 col-xl-5">
                              <button
                                type="submit"
                                className="btn primary-btn"
                                onClick={handleApplyClick}>
                                Apply
                              </button>
                              <button
                                type="submit"
                                className="btn secondary-btn"
                                onClick={handleCancelClick}>
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="col-xl-12">
                    <div className="sub-heading">
                      <h2>Report listing</h2>
                    </div>
                    <div className="table-blk table-responsive">
                      <table id="myTable" className="display border-0 ">
                        <thead>
                          <tr>
                            <th>Key name</th>
                            <th>Key Host Name</th>

                            <th>Title</th>
                            <th>Description</th>
                            <th>Key ID</th>
                            <th>Host ID</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.data?.reportedkeysdetails?.map(
                            (items: any, index: number) => (
                              <tr key={index}>
                                <td>{items.key_name}</td>
                                <td>{items.key_host_name}</td>

                                <td className="text-wrap">{items.title}</td>
                                <td className="text-wrap">
                                  {items.description}
                                </td>
                                <td>
                                  <span
                                    style={{
                                      cursor: "pointer",
                                      fontWeight: "600",
                                      color: "#72BCB8",
                                      fontSize: "15px",
                                    }}
                                    onClick={() =>
                                      handleClick(items.key_id, items.status)
                                    }>
                                    View Key
                                  </span>
                                </td>
                                <td>
                                  <span
                                    style={{
                                      cursor: "pointer",
                                      fontWeight: "600",
                                      color: "#72BCB8",
                                      fontSize: "15px",
                                    }}
                                    onClick={() =>
                                      handleHostClick(items.key_host_id)
                                    }>
                                    View Host
                                  </span>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                      {!data?.data ||
                      !data?.data?.reportedkeysdetails?.length ? (
                        <div className="d-flex justify-content-center align-items-center my-3 sub-heading">
                          <h2 className="m-0">No data found</h2>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <Pagination
                  totalPages={data?.data?.totalPages}
                  currentPage={pageNumber}
                  handlePrevPage={handlePrevPage}
                  handleNextPage={handleNextPage}
                  handleRowLimitChange={handleRowLimitChange}
                />
              </div>
            </div>
          </section>
        </div>
      </CustomLaoder>
    </div>
  );
};

export default Reports;
