import React, { useContext } from "react";
import {
  HashRouter as Router,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";
import { AuthContext } from "../context/authContext";
import Dashboard from "../screens/dashboard";
import LoginScreen from "../screens/auth/login";
import OwnerManagement from "../screens/ownersManagement";
import KeyHostManagement from "../screens/keyHostManagement";
import KeyManagement from "../screens/keyManagement";
import SubscriptionManagementScreen from "../screens/subscriptionMangement";
import TransactionManagement from "../screens/transactionMangement";
import Profile from "../screens/profile";
import PageNotFound from "../components/pageNotFound";
import Sidebar from "../components/sidebar";
import BoxManagement from "../screens/boxManagement";
import Notification from "../screens/notification";
import SendNotification from "../screens/notification/send_notification";
import QueriesManagement from "../screens/queriesManagement";
import ContentManagement from "../screens/contentManagement";
import Coupons from "../screens/coupon_section";
import Reports from "../screens/reports";
import StripeKey from "../screens/stripeManagement";
import HostRequests from "../screens/notificationList/list";
import UserLogin from "../screens/delete_section/userLogin";

const Navigation = () => {
  const { isAuthenticated } = useContext(AuthContext);

  const PublicRoute = () => {
    if (isAuthenticated) {
      return <Navigate to="/dashboard" replace />;
    }
    return <Outlet />;
  };

  const ProtectedRoute = () => {
    if (!isAuthenticated) {
      return <Navigate to="/login" replace />;
    }
    return (
      <>
        <Sidebar />
        <Outlet />
      </>
    );
  };

  return (
    <Router>
      <Routes>
        <Route element={<PublicRoute />}>
          <Route index element={<LoginScreen />} />
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/delete-account" element={<UserLogin />} />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route
            path="/ownerlisting"
            element={<OwnerManagement.OwnersListingScreen />}
          />
          <Route
            path="/ownerdetail"
            element={<OwnerManagement.OwnersDetailScreen />}
          />

          <Route
            path="/keyhostlisting"
            element={<KeyHostManagement.KeyHostListing />}
          />
          <Route
            path="/keyhostdetail"
            element={<KeyHostManagement.KeyHostDetailScreen />}
          />
          <Route
            path="/boxmanagementlisting"
            element={<BoxManagement.BoxManagementListing />}
          />
          <Route
            path="/boxmanagementdetail"
            element={<BoxManagement.BoxManagementDetail />}
          />
          <Route
            path="/keymanagementlisting"
            element={<KeyManagement.KeyManagementListing />}
          />
          <Route
            path="/keymanagementdetail"
            element={<KeyManagement.KeyManagementDetailScreen />}
          />
          <Route
            path="/subscriptionmanagement"
            element={<SubscriptionManagementScreen />}
          />
          <Route
            path="/queriesmanagement"
            element={<QueriesManagement.QueriesListing />}
          />
          <Route
            path="/transactionlisting"
            element={<TransactionManagement.TransactionListing />}
          />

          <Route path="/contentmanagement" element={<ContentManagement />} />
          <Route path="/sendnotification" element={<SendNotification />} />
          <Route path="/coupons" element={<Coupons />} />
          <Route path="/report" element={<Reports />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/notifications" element={<Notification />} />
          <Route path="/hostrequests" element={<HostRequests />} />
          <Route path="/stripe-key" element={<StripeKey />} />

          <Route path="*" element={<PageNotFound />} />
        </Route>
        {/* <Route path="*" element={<PageNotFound />} /> */}
      </Routes>
    </Router>
  );
};
export default Navigation;
