import React, { useEffect, useState } from "react";
import Header from "../../components/content_wrapper/header";
import CouponModal from "./couponModal";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useAddCoupon } from "../../hooks/couponApi/addCouponApi";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  addCouponInitialvalues,
  validationSchema,
} from "../../validations/addCouponValidation";
import generateCode from "../../utils/couponGenrateCode";
import { useGetPlans } from "../../hooks/subscriptionManagement/getPlans";
import { useGetCoupons } from "../../hooks/couponApi/getCouponApi";
import { DeleteSvg } from "../../utils/svgComponent";
import moment from "moment";
import Swal from "sweetalert2";
import { useDeleteCoupon } from "../../hooks/couponApi/deleteCouponApi";
import CustomLaoder from "../../components/customLoader";
import NoRecordFound from "../../components/noRecordFound";

const Coupons = () => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const [modalData, setModalData] = useState({
    open: false,
    modalTitle: "",
    modalType: "",
    data: {
      id: "",
      start_date: "",
      end_date: "",
      coupon_code: "",
      coupon_type: "",
    },
  });

  const couponCode = generateCode(10);
  const { data: couponData, isLoading } = useGetCoupons();
  const { mutateAsync: deleteCoupon } = useDeleteCoupon();
  const { mutateAsync } = useAddCoupon();
  const { data: data } = useGetPlans();
  const handleDeleteCoupon = (id: any) => {
    Swal.fire({
      title: "Are you sure",
      text: "You want to Delete this coupon?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#72bcb8",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await deleteCoupon({ coupon_id: id });
        toast.success("Delete Successfully");
      }
    });
  };

  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: addCouponInitialvalues,
    validationSchema: validationSchema,
    onSubmit: async () => {
      if (modalData.modalType === "Add") {
        try {
          const res: any = await mutateAsync({
            coupon_title: values?.title,
            coupon_code: couponCode,
            start_date: startDate && startDate.toLocaleDateString("en-CA"),
            end_date: endDate && endDate.toLocaleDateString("en-CA"),
            coupon_type: values.coupon_type,
            subscription_plan_id:
              values.plan_type === "1" ? values?.subscription_plan : "0",
            coupon_amount: values?.coupon_amount,
            plan_type: values.plan_type,
            description: values.description,
            users_type: values.users_type,
            limit: values.users_type === "1" ? "0" : values.limit,
          });
          if (res?.status === true) {
            toast.success(res?.message);
            setModalData({ ...modalData, open: false });
            resetForm();
            setStartDate(null);
            setEndDate(null);
          }
        } catch (error: any) {
          toast.error(error?.response?.data?.errors);
        }
      } else {
        return null;
      }
    },
  });
  useEffect(() => {
    setFieldValue("start_date", startDate);
    setFieldValue("end_date", endDate);
  }, [startDate, endDate]);

  const handleModalOpen = (e: any, data?: any) => {
    if (e.target.title === "Add") {
      setModalData({
        ...modalData,
        modalTitle: "Add Coupon",
        open: true,
        modalType: e.target.title,
      });
    }
  };

  const handleCloseModal = () => {
    setModalData({
      ...modalData,
      modalTitle: "",
      open: false,
    });
    resetForm();
    setEndDate(null);
    setStartDate(null);
  };
  const returnChild = () => {
    if (modalData.modalType === "Add") {
      return (
        <div className="common-modal-form addcoupon-modal">
          <div className="common-modal-field ">
            <div className="row">
              <div className="col-lg-6">
                <label>Title</label>
                <input
                  type="text"
                  className="form-control"
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Enter coupon title"
                />
                {touched.title && errors.title && (
                  <p className="error">{errors.title}</p>
                )}
              </div>
              <div className="col-lg-6">
                <label>Price</label>

                <input
                  type="text"
                  inputMode="numeric"
                  maxLength={6}
                  className="form-control"
                  name="coupon_amount"
                  value={values.coupon_amount}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Enter coupon amount"
                />
                {touched.coupon_amount && errors.coupon_amount && (
                  <p className="error">{errors.coupon_amount}</p>
                )}
              </div>
            </div>
          </div>
          <div className="common-modal-field ">
            <div className="row">
              <div className="col-lg-6">
                <label>Start Date</label>
                <DatePicker
                  className="form-control"
                  name="start_date"
                  selected={startDate}
                  onChange={(date: Date) => setStartDate(date)}
                  selectsStart
                  isClearable={startDate ? true : false}
                  startDate={startDate}
                  endDate={endDate}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Select Start Date"
                />
                {touched.start_date && errors.start_date && (
                  <p className="error">{errors.start_date}</p>
                )}
              </div>
              <div className="col-lg-6">
                <label>End Date</label>
                <DatePicker
                  className="form-control"
                  isClearable={endDate ? true : false}
                  selected={endDate}
                  onChange={(date: Date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  name="end_date"
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Select End Date"
                />
                {touched.end_date && errors.end_date && (
                  <p className="error">{errors.end_date}</p>
                )}
              </div>
            </div>
          </div>
          <div className="common-modal-field">
            <div>
              <label>Coupon Type</label>
            </div>
            <div className="form-check form-check-inline">
              <input
                type="radio"
                className="form-check-input"
                id="flatAmount"
                name="coupon_type"
                onChange={handleChange}
                value={"1"}
                checked={values.coupon_type === "1" ? true : false}
              />
              <label className="form-check-label" htmlFor="flatAmount">
                Flat Amount
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                type="radio"
                className="form-check-input"
                id="percentage"
                name="coupon_type"
                onChange={handleChange}
                value={"2"}
                checked={values.coupon_type === "2" ? true : false}
              />
              <label className="form-check-label" htmlFor="percentage">
                Percentage
              </label>
            </div>
            {touched.coupon_type && errors.coupon_type && (
              <p className="error">{errors.coupon_type}</p>
            )}
          </div>
          <div className="row common-modal-field">
            <div className="col-lg-6">
              <div className="common-modal-field">
                <div className="form-block p-relative">
                  <label>User type</label>
                  <select
                    name="users_type"
                    className="form-select"
                    onChange={handleChange}
                    value={values.users_type}
                    onBlur={handleBlur}>
                    <option value="" disabled>
                      Select Type
                    </option>
                    <option value="0">Limited Users</option>
                    <option value="1">Unlimited Users</option>
                  </select>
                  {touched.users_type && errors.users_type && (
                    <p className="error">{errors.users_type}</p>
                  )}
                </div>
              </div>
              {values.users_type === "0" && (
                <div className="common-modal-field">
                  <label>Limit </label>
                  <input
                    className="form-control"
                    type="number"
                    name="limit"
                    value={values.limit}
                    onChange={(e) => handleChange(e)}
                    onBlur={handleBlur}
                  />
                  {touched.limit && errors.limit && (
                    <p className="error">{errors.limit}</p>
                  )}
                </div>
              )}
            </div>
            <div className="col-lg-6">
              <div className="common-modal-field">
                <div className="form-block p-relative">
                  <label>Plan Type</label>
                  <select
                    name="plan_type"
                    className="form-select"
                    onChange={handleChange}
                    value={values.plan_type}
                    onBlur={handleBlur}>
                    <option value="" disabled>
                      Select Plan Type
                    </option>
                    <option value="0">All Plans</option>
                    <option value="1">Specific plan</option>
                  </select>
                  {touched.plan_type && errors.plan_type && (
                    <p className="error">{errors.plan_type}</p>
                  )}
                </div>
              </div>
              {values.plan_type === "1" && (
                <div className="common-modal-field">
                  <label>Subscription Plan </label>
                  <select
                    name="subscription_plan"
                    className="form-select"
                    onChange={handleChange}
                    value={values.subscription_plan}
                    onBlur={handleBlur}>
                    <option value="" disabled>
                      Select Plan
                    </option>
                    {data?.data?.subscription_plans.map((item: any) => (
                      <option value={item?._id}>{item.title}</option>
                    ))}
                  </select>
                  {touched.subscription_plan && errors.subscription_plan && (
                    <p className="error">{errors.subscription_plan}</p>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="common-modal-field">
            <label>Description</label>
            <textarea
              className="form-control"
              placeholder="Enter description"
              name="description"
              id="description"
              value={values.description}
              onChange={(e) => handleChange(e)}
              onBlur={handleBlur}
            />
            {touched.description && errors.description && (
              <p className="error">{errors.description}</p>
            )}
          </div>
        </div>
      );
    }
  };
  return (
    <div className="main-wrapper">
      <Header title="Coupons" />
      <div className="content-wrapper">
        <CustomLaoder loader={isLoading} />
        <section className="main-section profile-section spacer">
          <div className="container">
            <div className="row gy-3">
              <div className="col-xl-12">
                <div className="coupons-card-wrapper text-end mb-3">
                  <button
                    title="Add"
                    className="btn primary-btn me-0"
                    onClick={(e) => handleModalOpen(e)}>
                    + Add Coupon
                  </button>
                </div>
                {couponData?.data?.length !== 0 ? (
                  <div className="coupons-list-blk">
                    {couponData?.data?.map((item: any) => (
                      <div className="coupons-card-blk" key={item._id}>
                        <div className="coupons-card-inner">
                          <div className="coupons-card-text">
                            <div className="coupons-heading-flex">
                              <h2>{item?.title}</h2>
                              <div className="coupons-btn">
                                <button
                                  onClick={() => handleDeleteCoupon(item._id)}>
                                  <DeleteSvg color="#72BCB8" />
                                </button>
                              </div>
                            </div>
                            <div className="coupons-code">
                              <span>{item?.coupon_code}</span>
                            </div>
                            <p>{item?.description}</p>
                          </div>
                          <div className="coupon_types mt-2">
                            <div>
                              <p>
                                <b>Coupon Type:</b>
                                <span className="m-2">
                                  {item?.coupon_type === 1
                                    ? "Flat"
                                    : "Percentage"}
                                </span>
                              </p>
                              {item?.users_type === 0 && (
                                <p>
                                  <b>Users limit:</b>
                                  <span className="m-2">{item?.limit}</span>
                                </p>
                              )}
                            </div>
                            <div>
                              <p>
                                <b>User Type:</b>
                                <span className="m-2">
                                  {item?.users_type === 0
                                    ? "Limited"
                                    : "Unlimited"}
                                </span>
                              </p>
                              <p>
                                <b>Redeemed:</b>
                                <span className="m-2">
                                  {item?.times_redeemed}
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className="coupons-price">
                            {item?.coupon_type === 1 ? (
                              <span>£{item?.coupon_amount}</span>
                            ) : (
                              <span>{item?.coupon_amount}%</span>
                            )}
                          </div>

                          <div className="coupons-validity">
                            {moment(item.end_date).isBefore(moment(), "day") ? (
                              <React.Fragment>
                                <span
                                  style={{
                                    marginTop: "3px",
                                    display: "flex",
                                    alignItems: "center",
                                    borderRadius: "5px",
                                    fontSize: "13px",
                                    fontWeight: "700",
                                    padding: "0px 10px",
                                    color: "black",
                                  }}>
                                  EXPIRED
                                  <span
                                    style={{
                                      marginLeft: "3px",
                                      color: "white",
                                      borderRadius: "50%",
                                      fontSize: "12px",
                                      fontWeight: "700",
                                      padding: "0px 8px",
                                      backgroundColor: "red",
                                      cursor: "pointer",
                                    }}>
                                    !
                                  </span>
                                </span>
                              </React.Fragment>
                            ) : (
                              <span>
                                <b>Validity:</b>{" "}
                                {moment.utc(item?.start_date).format("ll")} to{" "}
                                {moment.utc(item?.end_date).format("ll")}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <NoRecordFound />
                )}
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* Add modal */}
      <CouponModal
        handleSubmit={() => handleSubmit()}
        modalTitle={modalData.modalTitle}
        open={modalData.open}
        handleCloseModal={handleCloseModal}>
        {returnChild()}
      </CouponModal>
    </div>
  );
};

export default Coupons;
