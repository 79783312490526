import { useQuery } from "react-query";
import { QueryReturnType } from "../../services/networking.service";
import ENDPOINTS from "../../services/endPoints";

interface IProps {
  page: string;
  limit: string;
  search: string;
}

export const useGetHostRequests = ({
  page,
  limit,
  search,
}: IProps): QueryReturnType<any> => {
  return useQuery<never, never, any>(
    [ENDPOINTS.GET_HOST_REQUESTS, { page: page, limit: limit, search: search }],
    {
      notifyOnChangeProps: [
        "data",
        "error",
        "refetch",
        "isRefetching",
        "isSuccess",
        "isLoading",
      ],
    }
  );
};
