import { useFormik } from "formik";
import Header from "../../components/content_wrapper/header";
import CustomLaoder from "../../components/customLoader";
import { useAddKey } from "../../hooks/tax_and_stripeKey/addKey";
import { toast } from "react-toastify";
import { stripeSchema, stripeValues } from "../../validations";
import { useGetStripeKey } from "../../hooks/tax_and_stripeKey/getStripeKey";
import { useState } from "react";
import NoRecordFound from "../../components/noRecordFound";

const StripeKey = () => {
  const [isMasked, setIsMasked] = useState(true);
  const [isMasked1, setIsMasked1] = useState(true);

  const { mutateAsync } = useAddKey();
  const { data, isLoading } = useGetStripeKey();

  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
    resetForm,
  } = useFormik({
    initialValues: stripeValues,
    validationSchema: stripeSchema,
    onSubmit: async () => {
      try {
        const res: any = await mutateAsync({
          external_key: values.key,
          public_key: values.publicKey,
          type: 2,
        });
        if (res?.status === true) {
          toast.success(res?.message);
          resetForm();
          const closeModelButton: any = document.getElementById("close-btn");
          closeModelButton.click();
          const closeModelBtn: any = document.getElementById("close-btn1");
          closeModelBtn.click();
        }
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
      }
    },
  });

  const handleOpenModal = () => {
    setValues({
      key: data?.data?.stripe_key || "",
      publicKey: data?.data?.stripe_public_key || "",
    });
  };
  const toggleMask = () => {
    setIsMasked1(!isMasked1);
  };
  return (
    <div className="main-wrapper">
      <Header title="Stripe Key" />
      <CustomLaoder loader={isLoading}>
        <div className="content-wrapper">
          <section className="main-section profile-section spacer">
            <div className="container">
              <div className="row gy-3">
                <div className="col-xl-12">
                  <div className="addsub-plan gap-3 align-content-center">
                    {data && Object.keys(data).length > 0 ? (
                      <button
                        title="Add"
                        className="btn primary-btn me-0"
                        data-bs-toggle="modal"
                        data-bs-target="#editStripeKey"
                        onClick={() => handleOpenModal()}>
                        Edit Stripe Key
                      </button>
                    ) : (
                      <button
                        title="Add"
                        className="btn primary-btn me-0"
                        data-bs-toggle="modal"
                        data-bs-target="#addStripeKey">
                        + Add Stripe Key
                      </button>
                    )}
                  </div>
                </div>

                {data && Object.keys(data).length > 0 ? (
                  <div className="wrapper-stripe-card">
                    <div className="row">
                      <div className="col-xl-12 mt-0">
                        <div className="tab-content-wrapper">
                          <div className="d-flex justify-content-between align-items-center">
                            <div>
                              <div
                                className="gap-3 d-flex flex-column"
                                style={{
                                  overflowWrap: "anywhere",
                                }}>
                                <div className="d-flex align-items-center gap-2">
                                  <b>Stripe Key :</b>
                                  <span>
                                    {isMasked
                                      ? "•".repeat(
                                          data?.data?.stripe_key.length
                                        )
                                      : data?.data?.stripe_key}
                                  </span>
                                  <i
                                    className={
                                      !isMasked
                                        ? "fa-regular fa-eye"
                                        : "fa-regular fa-eye-slash"
                                    }
                                    onClick={() => setIsMasked(!isMasked)}
                                  />
                                </div>
                                <div className="d-flex align-items-center gap-2">
                                  <b>Public Key :</b>
                                  <span className="hidden-text">
                                    {isMasked1
                                      ? "•".repeat(
                                          data?.data?.stripe_public_key.length
                                        )
                                      : data?.data?.stripe_public_key}
                                  </span>
                                  <i
                                    className={
                                      !isMasked1
                                        ? "fa-regular fa-eye"
                                        : "fa-regular fa-eye-slash"
                                    }
                                    onClick={() => toggleMask()}
                                  />
                                </div>
                              </div>
                            </div>
                            <div>
                              <img
                                alt="stripe"
                                className="w-100"
                                src="/assets/images/icons/stripe.svg"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <NoRecordFound />
                )}
              </div>
            </div>
          </section>
        </div>
      </CustomLaoder>
      {/* add */}
      <div
        className="modal fade common-modal "
        id="addStripeKey"
        tabIndex={-1}
        aria-labelledby="changeContentLabel"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <button
                id="close-btn"
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => resetForm()}
              />
              <div className="common-box-body">
                <h2>Stripe Key</h2>
                <div className="common-modal-form">
                  <div className="common-modal-field">
                    <label htmlFor="confirmPassword">Key</label>
                    <input
                      placeholder="Enter Stripe Key"
                      className="form-control"
                      type="text"
                      name="key"
                      onChange={handleChange}
                      value={values.key}
                      onBlur={handleBlur}
                    />
                    {touched.key && errors.key && (
                      <p className="error">{errors.key}</p>
                    )}
                  </div>
                </div>
                <div className="common-modal-field">
                  <label htmlFor="confirmPassword">Public Key</label>
                  <input
                    placeholder="Enter Public Key"
                    className="form-control"
                    type="text"
                    name="publicKey"
                    onChange={handleChange}
                    value={values.publicKey}
                    onBlur={handleBlur}
                  />
                  {touched.publicKey && errors.publicKey && (
                    <p className="error">{errors.publicKey}</p>
                  )}
                </div>
                <div className="common-modal-btns">
                  <button
                    className="btn primary-btn me-0"
                    onClick={() => handleSubmit()}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* update */}
      <div
        className="modal fade common-modal "
        id="editStripeKey"
        tabIndex={-1}
        aria-labelledby="changeContentLabel"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <button
                id="close-btn1"
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => resetForm()}
              />
              <div className="common-box-body">
                <h2>Update Stripe Key</h2>
                <div className="common-modal-form">
                  <div className="common-modal-field">
                    <label htmlFor="confirmPassword">Key</label>
                    <input
                      placeholder="Enter Stripe Key"
                      className="form-control"
                      type="text"
                      name="key"
                      onChange={handleChange}
                      value={values.key}
                      onBlur={handleBlur}
                    />
                    {touched.key && errors.key && (
                      <p className="error">{errors.key}</p>
                    )}
                  </div>
                  <div className="common-modal-field">
                    <label htmlFor="confirmPassword">Public Key</label>
                    <input
                      placeholder="Enter Public Key"
                      className="form-control"
                      type="text"
                      name="publicKey"
                      onChange={handleChange}
                      value={values.publicKey}
                      onBlur={handleBlur}
                    />
                    {touched.publicKey && errors.publicKey && (
                      <p className="error">{errors.publicKey}</p>
                    )}
                  </div>
                </div>
                <div className="common-modal-btns">
                  <button
                    className="btn primary-btn me-0"
                    onClick={() => handleSubmit()}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StripeKey;
