import * as Yup from "yup";
//initial values
const keyHostinitialValues = {
  username: "",
  email: "",
  store_name: "",
  store_info: "",
  address: "",
  lat: "",
  lng: "",
  phoneNumber: "",
  countryCode: "",
  country: "",
  selectedOption: [],
};

//  validations schemas
const keyHostValidationSchema = Yup.object().shape({
  username: Yup.string()
    .min(3, "Username must be at least 3 characters")
    .required("Username is required*"),
  email: Yup.string()
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Invalid email address"
    )
    .required("Email is required*"),
  country: Yup.string().required("Country is required*"),
  address: Yup.string().required("Address is required*"),
  store_name: Yup.string().required("Store name is required*"),
  store_info: Yup.string().required("Store info is required*"),
  lat: Yup.string()
    .required("Latitude is required")
    .matches(/^-?[1-8]?\d(?:\.\d{1,15})?$/, "Latitude must be a valid number"),
  lng: Yup.string()
    .required("Longitude is required")
    .matches(
      /^-?(?:1[0-7]|[1-9])?\d(?:\.\d{1,15})?$/,
      "Longitude must be a valid number"
    ),
  phoneNumber: Yup.string()
    .required("Phone number is required")
    .matches(/^\d+$/, "Phone number must be digits only")
    .min(10, "Phone number must be exactly 10 digits"),
  countryCode: Yup.number().required("Country code is required*"),
  selectedOption: Yup.array()
    .min(1, "Please select at least one option*")
    .required("Please select at least one option*"),
});

export { keyHostinitialValues, keyHostValidationSchema };
