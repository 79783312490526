import { useMutation, useQueryClient } from "react-query"
import { mutatePostFn } from "../../services/networking.service"
import ENDPOINTS from "../../services/endPoints"
import { Auth } from "../../interface"

interface Payload {
    email: string
    password: string
}

export const useAdminLogin = () => {
    const queryClient = useQueryClient()
    return useMutation<Auth, never, Payload>(data => mutatePostFn(ENDPOINTS.ADMIN_LOGIN, data), {
        onSuccess: async () => {
            queryClient.invalidateQueries([])
        },
    })
}