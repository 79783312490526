const sideBarArray = [
  {
    name: "Dashboard",
    path: "/dashboard",
  },
  {
    name: "Owners",
    path: "/ownerlisting",
  },
  {
    name: "Hosts",
    path: "/keyhostlisting",
  },
  {
    name: "Keys",
    path: "/keymanagementlisting",
  },
  {
    name: "Boxes",
    path: "/boxmanagementlisting",
  },
  {
    name: "Queries",
    path: "/queriesmanagement",
  },
  {
    name: "Reports",
    path: "/report",
  },
  {
    name: "Content",
    path: "/contentmanagement",
  },

  {
    name: "Coupons",
    path: "/coupons",
  },
  {
    name: "Stripe Key",
    path: "/stripe-key",
  },
  {
    name: "Transactions",
    path: "/transactionlisting",
  },
  {
    name: "Host Requests",
    path: "/hostrequests",
  },
  {
    name: "Subscriptions",
    path: "/subscriptionmanagement",
  },

  {
    name: "Send Notification",
    path: "/sendnotification",
  },
];

export { sideBarArray };
