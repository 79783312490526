import React, { useState } from "react";
import { DeleteSvg, EditSvg, EyeSvg } from "../../utils/svgComponent";
import Header from "../../components/content_wrapper/header";
import { useNavigate } from "react-router-dom";
import { useGetKeyList } from "../../hooks/keyManagement/keyListing";
import Pagination from "../../utils/pagination";
import CustomLaoder from "../../components/customLoader";
import { useDeleteKey } from "../../hooks/keyManagement/deleteKey";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
interface Status {
  className: string;
  label: string;
}
const KeyManagementListing: React.FC = () => {
  const [pageNumber, setPageNumber] = useState<any>(1);
  const [countData, setCountData] = useState<any>(20);
  const [searchValue, setSearchValue] = useState<string>("");
  const [finalValue, setFinalValue] = useState<string>("");
  const { data: GetKey, isLoading } = useGetKeyList({
    page: pageNumber,
    limit: countData,
    search: finalValue,
  });

  const { mutate: deleteKey } = useDeleteKey();

  const handleDeleteKey = (id: any) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this Key?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#72bcb8",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteKey(
          { key_id: id },
          {
            onSuccess: (res) => {
              toast.success("Deleted Successfully");
            },
            onError: (error: any) => {
              const errorMessage =
                error?.response?.data?.message ||
                "An error occurred while deleting the key";
              toast.error(errorMessage);
            },
          }
        );
      }
    });
  };
  const navigate = useNavigate();
  const handleClick = (id: string, status: any) => {
    navigate("/keymanagementdetail", {
      state: {
        id,
        status,
      },
    });
  };
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };
  const handlePrevPage = () => {
    setPageNumber((prevPage: any) => prevPage - 1);
  };

  const handleNextPage = () => {
    setPageNumber((nextPage: any) => nextPage + 1);
  };
  const handleRowLimitChange = (newValue: number) => {
    setCountData(newValue);
  };
  const handleApplyClick = () => {
    setFinalValue(searchValue);
  };

  const handleCancelClick = () => {
    setFinalValue("");
    setSearchValue("");
  };

  const statusMap: { [key: number]: Status } = {
    0: { className: "c-pill--warning", label: "Waiting dropoff" },
    1: { className: "c-pill--success", label: "In Keybox" },
    2: { className: "c-pill c-pill--danger", label: "Not In Keybox" },
    3: { className: "c-pill c-pill--danger", label: "Deleted" },
  };
  return (
    <div className="main-wrapper">
      <Header title="Keys" />
      <CustomLaoder loader={isLoading}>
        <div className="content-wrapper">
          <section className="main-section coach-section spacer">
            <div className="container">
              <div className="wrapper-card">
                <div className="row gy-3">
                  <div className="col-xl-12">
                    <form>
                      <div className="row justify-content-between g-3">
                        <div className="col-lg-8 col-xl-8 col-xxl-6">
                          <div className="row align-items-center gy-3">
                            <div className="col-sm-7 col-xl-7">
                              <div className="form-block p-relative">
                                <input
                                  type="search"
                                  className="form-control"
                                  id="search"
                                  placeholder="Search Key by Name or Email"
                                  value={searchValue}
                                  onChange={handleSearchChange}
                                />
                                <span className="search-icon" />
                              </div>
                            </div>
                            <div className="col-12 col-sm-5 col-xl-5">
                              <button
                                type="submit"
                                className="btn primary-btn"
                                onClick={handleApplyClick}>
                                Apply
                              </button>
                              <button
                                type="submit"
                                className="btn secondary-btn"
                                onClick={handleCancelClick}>
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="col-xl-12">
                    <div className="sub-heading">
                      <h2>Key listing</h2>
                    </div>
                    <div className="table-blk table-responsive">
                      <table id="myTable" className="display border-0">
                        <thead>
                          <tr>
                            <th>Key name</th>
                            <th>Owner Email</th>
                            <th>Register ID</th>
                            <th>Box ID</th>
                            <th>Slot No.</th>
                            <th>Subscription</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {GetKey?.data?.keyList.map(
                            (items: any, index: number) => (
                              <tr key={index}>
                                <td>{items.name}</td>
                                <td>{items.key_owner_email}</td>
                                <td>{items.register_id}</td>
                                <td>{items.box_id}</td>
                                <td>{items.key_slot_number}</td>
                                <td>{items.key_subscription_status}</td>
                                <td>
                                  <span
                                    // className={`table-status ${
                                    //   statusMap[items.status]?.className ||
                                    //   "st-deleted"
                                    // }`}
                                    className={`c-pill 
                                    ${
                                      statusMap[items.status]?.className ||
                                      "c-pill--danger"
                                    }`}>
                                    {statusMap[items.status]?.label ||
                                      "Deleted"}
                                  </span>
                                </td>
                                <td>
                                  <span className="table-action">
                                    <a
                                      onClick={() =>
                                        handleClick(items._id, items.status)
                                      }
                                      className="table-eye">
                                      <EyeSvg />
                                    </a>
                                    <a
                                      onClick={() => handleDeleteKey(items._id)}
                                      className="table-eye">
                                      <DeleteSvg color="#72BCB8" />
                                    </a>
                                  </span>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                      {GetKey?.data?.keyList?.length === 0 ? (
                        <div className="d-flex justify-content-center align-items-center my-3 sub-heading">
                          <h2 className="m-0">No data found</h2>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <Pagination
                  totalPages={GetKey?.data?.totalPages}
                  currentPage={pageNumber}
                  handlePrevPage={handlePrevPage}
                  handleNextPage={handleNextPage}
                  handleRowLimitChange={handleRowLimitChange}
                />
              </div>
            </div>
          </section>
        </div>
      </CustomLaoder>
    </div>
  );
};

export default KeyManagementListing;
