import React from "react";
type Props = {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const ToggleButton: React.FC<Props> = ({ checked, onChange }) => {
  return (
    <div className="toggle-switch">
      <input
        className="toggle-input"
        id="toggle"
        type="checkbox"
        checked={checked}
        onChange={onChange}
      />
      <label className="toggle-label" htmlFor="toggle" />
    </div>
  );
};

export default ToggleButton;
