import { useFormik } from "formik";
import React, { useState } from "react";
import { LogininitialValues, loginValidationSchema } from "../../validations";
import { toast } from "react-toastify";
import { useUserLogin } from "../../hooks/auth/userLogin";
import { useDeleteOwnerUser } from "../../hooks/auth/deleteOwnerUser";
import userGlobalConfig from "../../constants/globalConfig";
import CustomLaoder from "../../components/customLoader";
import { useNavigate } from "react-router-dom";

const UserLogin = () => {
  const { mutateAsync } = useUserLogin();
  const { mutateAsync: deleteOwnerUser } = useDeleteOwnerUser();

  const navigate = useNavigate();

  const [showEye, setShowEye] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const { values, touched, errors, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: LogininitialValues,
      validationSchema: loginValidationSchema,
      onSubmit: async () => {
        setLoading(true);
        try {
          const res: any = await mutateAsync({
            email: values.email,
            password: values.password,
            fcmKey: "",
          });
          if (res?.status === true) {
            localStorage.removeItem(userGlobalConfig.TOKEN);
            localStorage.setItem(userGlobalConfig.USER_TOKEN, res?.data?.token);

            setTimeout(async () => {
              try {
                setLoadingDelete(true);
                const deleteResponse: any = await deleteOwnerUser();

                if (deleteResponse?.status === true) {
                  toast.success(deleteResponse?.message);
                  localStorage.removeItem(userGlobalConfig.USER_TOKEN);
                  navigate("/login");
                } else {
                  toast.error("User not verified. Unable to delete account.");
                }
              } catch (deleteError: any) {
                toast.error(
                  deleteError?.response?.data?.message ||
                    "An error occurred while deleting user."
                );
              } finally {
                setLoadingDelete(false);
              }
            }, 1000);
          }
        } catch (error: any) {
          toast.error(error?.response?.data?.message);
        } finally {
          setLoading(false);
        }
      },
    });
  return (
    <CustomLaoder loader={loadingDelete}>
      <section className="login-section">
        <div className="container-fluid g-0">
          <div className="row g-0">
            <div className="col-md-12">
              <div className="login-main-blk">
                <div className="login-form-blk">
                  <div className="login-heading">
                    <h1 className="pb-2">Account Deletion</h1>
                    <span>
                      Please verify your account to proceed with deletion.
                    </span>
                  </div>
                  <form>
                    <div className="form-group">
                      <label htmlFor="email">Your email</label>
                      <input
                        className={
                          errors.email && touched.email
                            ? "form-control border border-danger"
                            : "form-control"
                        }
                        id="email"
                        placeholder="Email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.email && touched.email && (
                        <p className="error">{errors.email}</p>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="psw">Password</label>

                      <div className="p-relative">
                        <span className="eye">
                          <i
                            className={
                              !showEye
                                ? "fa-regular fa-eye"
                                : "fa-regular fa-eye-slash"
                            }
                            onClick={() => setShowEye(!showEye)}
                          />
                        </span>
                        <input
                          id="psw"
                          type={!showEye ? "password" : "text"}
                          className={
                            errors.email && touched.email
                              ? "form-control border border-danger"
                              : "form-control"
                          }
                          placeholder="Password"
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>

                      {errors.password && touched.password && (
                        <p className="error">{errors.password}</p>
                      )}
                    </div>
                    <div className="login-btn-blk">
                      <button
                        type="button"
                        disabled={loading}
                        className="btn primary-btn"
                        onClick={() => handleSubmit()}>
                        {loading ? "Processing..." : "Login and Delete Account"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </CustomLaoder>
  );
};

export default UserLogin;
