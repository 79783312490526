import Axios, {
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
} from "axios";
import {
  QueryClient,
  QueryObserverResult,
  UseBaseMutationResult,
  UseInfiniteQueryResult,
} from "react-query";
import userGlobalConfig from "../constants/globalConfig";
export type QueryReturnType<T> = QueryObserverResult<T, never>;
export type InfiniteQueryReturnType<T> = UseInfiniteQueryResult<T, never>;
export type MutateReturnType<T, U, V> = UseBaseMutationResult<T, V, U, unknown>;

const apiBaseUrl = process.env.REACT_APP_BASE_URL;

export const client = Axios.create({
  baseURL: apiBaseUrl,
});

async function authRequestInterceptor(config: AxiosRequestConfig) {
  const encodedToken =
    localStorage.getItem(userGlobalConfig.TOKEN) ||
    localStorage.getItem(userGlobalConfig.USER_TOKEN);
  if (encodedToken) {
    config.params = { ...config.params };
  }
  if (encodedToken && config.headers) {
    config.headers = {
      ...config.headers,
      Authorization: "Bearer " + encodedToken,
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "Content-Type",
    };
  }
  return config;
}

client.interceptors.request.use(authRequestInterceptor as never);

export const mutatePostFn = async <T, U>(
  key: string,
  data: T,
  params?: Record<string, unknown>,
  headers?: AxiosRequestHeaders
): Promise<U> => {
  const response = await client.post<T, AxiosResponse<U>>(key, data, {
    params,
    headers,
  });
  return response.data;
};

export const mutatePatchFn = async <T, U>(
  key: string,
  data: T,
  params?: Record<string, unknown>
): Promise<U> => {
  const response = await client.patch<T, AxiosResponse<U>>(key, data, {
    params,
  });
  return response.data;
};

export const mutatePutFn = async <T, U>(
  key: string,
  data: T,
  params?: Record<string, unknown>
): Promise<U> => {
  const response = await client.put<T, AxiosResponse<U>>(key, data, {
    params,
  });
  return response.data;
};

export const mutateDeleteFn = async <T, U>(
  key: string,
  data: T,
  params?: Record<string, unknown>
): Promise<U> => {
  const response = await client.delete<T, AxiosResponse<U>>(key, {
    data,
    params,
  });
  return response.data;
};

const formatQueryParams = (
  params: Record<string, string | number> = {},
  pageParam?: number
) => {
  if (params || pageParam) {
    let qs = Object.keys(params)
      .map((key) => {
        const val = params[key];
        return `${val === null || val === undefined ? "" : `${key}=${val}`}`;
      })
      .filter(Boolean)
      .join("&");
    if (
      pageParam !== null &&
      pageParam !== undefined &&
      typeof pageParam === "number"
    ) {
      qs = qs.replace(`page=${pageParam - 1}`, "").concat(`&page=${pageParam}`);
    }
    return qs;
  }
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: async ({ queryKey: [url, params], pageParam = null }) => {
        if (typeof url === "string") {
          const queryParams = formatQueryParams(params as never, pageParam);
          const { data } = await client.get(
            `${url.toLowerCase()}${queryParams ? `?${queryParams}` : ""}`
          );
          return data;
        }
        throw new Error("Invalid QueryKey");
      },
    },
  },
});
