import React, { useEffect, useState } from "react";
import Header from "../../components/content_wrapper/header";
import { useGetPlans } from "../../hooks/subscriptionManagement/getPlans";
import { useAddPlan } from "../../hooks/subscriptionManagement/addPlan";
import {
  addMonthValidationSchema,
  addPlanValues,
  addTaxValidationSchema,
  addTaxValues,
  combinedValidationSchema,
} from "../../validations/plansValidation";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useEditPrice } from "../../hooks/subscriptionManagement/editPrice";
import SubscriptionModal from "./subscriptionModal";
import { usePlanStatus } from "../../hooks/subscriptionManagement/planStatus";
import { DeleteSvg, EditSvg } from "../../utils/svgComponent";
import Swal from "sweetalert2";
import { useDeletePlan } from "../../hooks/subscriptionManagement/deletePlan";
import NoRecordFound from "../../components/noRecordFound";
import CustomLaoder from "../../components/customLoader";
import { useAddKey } from "../../hooks/tax_and_stripeKey/addKey";
import ToggleButton from "../../components/toggleButton";

const SubscriptionManagementScreen: React.FC = () => {
  const [planType, setPlanType] = useState("");
  const [modalData, setModalData] = useState({
    open: false,
    modalTitle: "",
    modalType: "",
    data: {
      id: "",
      price: "",
      tax: "",
    },
  });

  const { data: data, isError, isLoading } = useGetPlans();
  const { mutateAsync } = useAddPlan();
  const { mutateAsync: updateMutation } = useEditPrice();
  const { mutateAsync: updateStatus } = usePlanStatus();
  const { mutateAsync: deletePlan } = useDeletePlan();
  const { mutateAsync: addKey } = useAddKey();

  const [showNotes, setShowNotes] = useState(false);

  useEffect(() => {
    if (data?.data?.tax_notes !== undefined) {
      setShowNotes(data?.data?.tax_notes);
    }
  }, [data]);
  const handleCheckboxChange = async () => {
    const newShowNotes = !showNotes;
    setShowNotes(newShowNotes);

    try {
      const res: any = await addKey({
        type: 3,
        external_key: newShowNotes,
      });
      if (res?.status) {
        toast.success(res.message);
      } else {
        toast.error("Failed to update notes.");
      }
    } catch (error) {
      toast.error("An error occurred while updating the notes.");
      console.error("Error updating notes:", error);
    }
  };

  let completeInitialValue;
  let completeInitialSchema;

  if (planType === "month") {
    completeInitialValue = addPlanValues;
    completeInitialSchema = addMonthValidationSchema;
  } else {
    completeInitialValue = addPlanValues;
    completeInitialSchema = combinedValidationSchema;
  }

  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
    resetForm,
  } = useFormik({
    initialValues: completeInitialValue,
    validationSchema: completeInitialSchema,
    onSubmit: async () => {
      const percentage = data?.data?.tax_percentage || 0;
      const totalPriceWithTax = Number(values.price) * (1 - percentage / 100);
      const taxPrice = Number(values.price) * (percentage / 100);

      if (modalData.modalType === "Edit") {
        // Handle edit submission
        try {
          const res: any = await updateMutation({
            plan_id: modalData?.data?.id,
            price: totalPriceWithTax.toFixed(2),
            tax_price: taxPrice.toFixed(2),
          });
          if (res?.status === true) {
            toast.success(res?.message);
            setModalData({ ...modalData, open: false });
            resetForm();
          }
        } catch (error: any) {
          toast.error(error?.response?.data?.message);
        }
      } else if (modalData.modalType === "Add") {
        // Handle add submission
        try {
          const res: any = await mutateAsync({
            title: values.title,
            timePeriod: values.monthType, // Use monthType for monthly plans
            price: totalPriceWithTax.toFixed(2),
            type: values.type,
            tax_price: taxPrice.toFixed(2),
          });
          if (res?.status === true) {
            toast.success(res?.message);
            setModalData({ ...modalData, open: false });
            resetForm();
          }
        } catch (error: any) {
          toast.error(error?.response?.data?.message);
        }
      } else {
        return null;
      }
    },
  });

  const {
    values: taxValue,
    touched: taxTouched,
    errors: taxErrors,
    handleBlur: taxHandleBlur,
    handleChange: taxHandleChange,
    handleSubmit: taxHandleSubmit,
    setValues: taxSetValues,
    resetForm: taxReset,
  } = useFormik({
    initialValues: addTaxValues,
    validationSchema: addTaxValidationSchema,
    onSubmit: async () => {
      if (modalData.modalType === "taxEdit") {
        // Handle edit submission
        try {
          const res: any = await addKey({
            type: 1,
            external_key: taxValue.tax,
          });
          if (res?.status === true) {
            toast.success(res?.message);
            setModalData({ ...modalData, open: false });
            taxReset();
          }
        } catch (error: any) {
          toast.error(error?.response?.data?.message);
        }
      } else if (modalData.modalType === "tax") {
        try {
          const res: any = await addKey({
            type: 1,
            external_key: taxValue.tax,
          });
          if (res?.status === true) {
            toast.success(res?.message);
            setModalData({ ...modalData, open: false });
            taxReset();
          }
        } catch (error: any) {
          toast.error(error?.response?.data?.message);
        }
      } else {
        return null;
      }
    },
  });

  const percentage = data?.data?.tax_percentage || 0;
  const totalPriceWithTax = Number(values.price) * (1 - percentage / 100);
  const handleModalOpen = (e: any, data?: any) => {
    if (e.target.title === "tax") {
      setModalData({
        ...modalData,
        modalTitle: "Add Tax",
        open: true,
        modalType: e.target.title,
      });
    }
    if (e.target.title === "taxEdit") {
      setModalData({
        ...modalData,
        modalTitle: "Edit Tax",
        open: true,
        modalType: e.target.title,
        data: { id: data?._id, tax: data, price: "" },
      });
      taxSetValues({
        tax: data,
      });
    }
    if (e.target.title === "Add") {
      setModalData({
        ...modalData,
        modalTitle: "Add Plan",
        open: true,
        modalType: e.target.title,
        // data: { id: "", price: "" },
      });
    }
    if (e.target.title === "Edit") {
      setModalData({
        ...modalData,
        modalTitle: "Edit Price",
        open: true,
        modalType: e.target.title,
        data: { id: data?._id, price: data.total_price, tax: "" },
      });
      setValues({
        price: data?.total_price,
        title: "title",
        type: "type",
        monthType: "1",
      });
    } else return null;
  };

  const handleCloseModal = () => {
    setModalData({
      ...modalData,
      modalTitle: "",
      open: false,
    });
    resetForm();
    taxReset();
  };

  const returnChild = () => {
    if (modalData.modalType === "tax") {
      return (
        <div className="common-modal-form">
          <div className="common-modal-field">
            <label>Tax value</label>
            <input
              placeholder="Enter the tax percentage"
              className="form-control"
              type="number"
              name="tax"
              onChange={taxHandleChange}
              value={taxValue.tax}
              onBlur={taxHandleBlur}
            />
            {taxTouched.tax && taxErrors.tax && (
              <p className="error">{taxErrors.tax}</p>
            )}
          </div>
        </div>
      );
    }
    if (modalData.modalType === "taxEdit") {
      return (
        <div className="common-modal-form">
          <div className="common-modal-field">
            <label>Update Tax</label>
            <input
              type="text"
              name="tax"
              className="form-control"
              placeholder="Enter the tax percentage"
              value={taxValue.tax}
              onChange={taxHandleChange}
              onBlur={taxHandleBlur}
            />
            {taxTouched.tax && taxErrors.tax && (
              <p className="error">{taxErrors.tax}</p>
            )}
          </div>
        </div>
      );
    }
    if (modalData.modalType === "Add") {
      return (
        <div className="common-modal-form">
          <div className="common-modal-field">
            <div className="common-modal-field">
              <label>Select the time period for the plan (month or year)</label>
              <select
                name="type"
                className="form-select"
                onChange={(e) => {
                  handleChange(e);
                  setPlanType(e.target.value);
                }}
                value={values.type}
                onBlur={handleBlur}>
                <option value="" disabled>
                  Select Type
                </option>
                <option value="month">Month</option>
                <option value="year">Year</option>
              </select>
              {touched.type && errors.type && (
                <p className="error">{errors.type}</p>
              )}
            </div>

            <div className="common-modal-field">
              <label>Enter the number of months or years for the plan</label>
              <input
                placeholder="Enter the number of months/years"
                className="form-control"
                type="number"
                name="monthType"
                value={values.monthType}
                onChange={(e) => handleChange(e)}
                onBlur={handleBlur}
              />
              {touched.monthType && errors.monthType && (
                <p className="error">{errors.monthType}</p>
              )}
            </div>
          </div>

          <div className="common-modal-field">
            <label>Enter Plan Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Plan Name"
              name="title"
              onChange={handleChange}
              value={values.title}
              onBlur={handleBlur}
            />
            {touched.title && errors.title && (
              <p className="error">{errors.title}</p>
            )}
          </div>
          <div className="common-modal-field">
            <label>Enter Price (including tax)</label>
            <input
              type="number"
              min={"0"}
              max="999"
              className="form-control"
              placeholder="Enter Price"
              name="price"
              value={values.price}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {touched.price && errors.price && (
              <p className="error">{errors.price}</p>
            )}
          </div>
          {values.price && (
            <p>
              You will get <strong>{totalPriceWithTax.toFixed(2)}</strong>{" "}
              excluding tax
            </p>
          )}
        </div>
      );
    }
    if (modalData.modalType === "Edit") {
      return (
        <div className="common-modal-form">
          <div className="common-modal-field">
            <label>Update Price</label>
            <input
              type="text"
              name="price"
              className="form-control"
              placeholder="Enter Price"
              value={values.price}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {touched.price && errors.price && (
              <p className="error">{errors.price}</p>
            )}
          </div>
          {values.price && (
            <p>
              You will get <strong>{totalPriceWithTax.toFixed(2)}</strong>{" "}
              excluding tax
            </p>
          )}
        </div>
      );
    }
  };
  // update status
  const handleToggleClick = async (id: string, action: any) => {
    let Status = action === true ? false : true;
    try {
      await updateStatus({
        plan_id: id,
        plan_status: Status,
      });
    } catch (error: any) {
      console.error("Unexpected error:", error);
    }
  };
  // delete Plan
  const handleDeleteCoupon = (id: any) => {
    Swal.fire({
      title: "Are you sure",
      text: "You want to Delete this Plan?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#72bcb8",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await deletePlan({ plan_id: id });
        toast.success("Delete Successfully");
      }
    });
  };
  return (
    <div className="main-wrapper">
      <Header title="Subscriptions" />
      <CustomLaoder loader={isLoading}>
        <div className="content-wrapper">
          <section className="main-section profile-section spacer">
            <div className="container">
              <div className="row gy-3">
                <div className="col-xl-12">
                  <div className="addsub-plan gap-3 align-content-center">
                    <div className="d-flex gap-4">
                      {data?.data?.tax_percentage ? (
                        <div className="d-flex align-items-center gap-5">
                          <div className="bg-gradient rounded-start-pill">
                            <span
                              style={{
                                WebkitTextStroke: "thin",
                                marginLeft: "15px",
                                fontWeight: "bold",
                              }}>
                              Tax Value ({data?.data?.tax_percentage}%)
                              <button
                                title="taxEdit"
                                className="table-edit m-2"
                                style={{
                                  cursor: "pointer",
                                  border: "none",
                                  backgroundColor: "transparent",
                                  padding: 0,
                                }}
                                onClick={(e) =>
                                  handleModalOpen(e, data?.data?.tax_percentage)
                                }>
                                <EditSvg />
                              </button>
                            </span>
                          </div>
                        </div>
                      ) : (
                        <button
                          title="tax"
                          className="btn primary-btn me-0"
                          onClick={(e) => handleModalOpen(e)}>
                          Add Tax
                        </button>
                      )}
                      <div className="d-flex gap-2 align-items-center">
                        {/* <label className="form-check-label">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={showNotes}
                              onChange={handleCheckboxChange}
                            />
                          </label> */}
                        <ToggleButton
                          checked={showNotes}
                          onChange={handleCheckboxChange}
                        />
                        <span style={{ fontWeight: "400", fontSize: "13px" }}>
                          Show Notes
                        </span>
                      </div>
                    </div>

                    <button
                      title="Add"
                      className="btn primary-btn me-0"
                      onClick={(e) => handleModalOpen(e)}>
                      + Add New Plan
                    </button>
                  </div>
                  <div className="subscription-card-wrapper">
                    <div className="subscription-flex-card">
                      {data?.data?.subscription_plans.map(
                        (items: any, index: number) => (
                          <div
                            className="subscription-card text-capitalize d-flex flex-column justify-content-between text-break"
                            key={index}>
                            <button
                              className="del-sub-card-btn"
                              onClick={() => handleDeleteCoupon(items?._id)}>
                              <DeleteSvg color="#fff" />
                            </button>
                            <h2>{items.title}</h2>
                            <span className="subscription-price">{`£${
                              items.total_price
                            }/ ${
                              items.timePeriod === null
                                ? "" +
                                  items?.type.charAt(0).toUpperCase() +
                                  items?.type.slice(1)
                                : items.timePeriod +
                                  items?.type.charAt(0).toUpperCase() +
                                  items?.type.slice(1)
                            }`}</span>
                            <div className="subscription-action-btn">
                              <button
                                title="Edit"
                                className="btn primary-btn me-0"
                                onClick={(e) => handleModalOpen(e, items)}>
                                Edit Price
                              </button>
                              <button
                                className={
                                  items?.suspended === false
                                    ? "btn black-btn"
                                    : "btn primary-btn me-0"
                                }
                                onClick={() =>
                                  handleToggleClick(items._id, items?.suspended)
                                }>
                                {items?.suspended === false
                                  ? "Suspend"
                                  : "Active"}
                              </button>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                  {data?.data?.length === 0 || isError === true ? (
                    <NoRecordFound />
                  ) : null}
                </div>
              </div>
            </div>
          </section>
        </div>
      </CustomLaoder>
      <SubscriptionModal
        handleSubmit={() => {
          handleSubmit();
          taxHandleSubmit();
        }}
        modalTitle={modalData.modalTitle}
        open={modalData.open}
        handleCloseModal={handleCloseModal}>
        {returnChild()}
      </SubscriptionModal>
    </div>
  );
};

export default SubscriptionManagementScreen;
