import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useGetKeyHost } from "../../hooks/keyHostManagementApi/getKeyHost";
import Pagination from "../../utils/pagination";
import Header from "../../components/content_wrapper/header";
import { EyeSvg } from "../../utils/svgComponent";
import { useUpdateKeyHostStatus } from "../../hooks/keyHostManagementApi/updateStatus";
import { getCountries, getCountryCallingCode } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useAddKeyHost } from "../../hooks/keyHostManagementApi/addKeyHost";
import { useFormik } from "formik";
import {
  keyHostValidationSchema,
  keyHostinitialValues,
} from "../../validations/addKeyHostValidation";
import en from "react-phone-number-input/locale/en.json";
import { toast } from "react-toastify";
import { useGetBoxes } from "../../hooks/boxManagementApi/getBoxes";
import KeyHostModal from "./keyHostModal";
import { useUpdateKeyHost } from "../../hooks/keyHostManagementApi/updateKeyHost";
import { useResendPassword } from "../../hooks/keyHostManagementApi/resendHostPassword";
import CustomLaoder from "../../components/customLoader";
export interface TimeSlot {
  day: string;
  start_time: string;
  end_time: string;
  closed: boolean;
}
const days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const KeyHostListing: React.FC = () => {
  const initialTimeSlots: TimeSlot[] = days.map((day, index) => ({
    id: index,
    day,
    start_time: "",
    end_time: "",
    closed: false,
  }));
  const [modalState, setModalState] = useState<any>({
    modalType: "",
    open: false,
    data: {},
  });

  const [timeSlots, setTimeSlots] = useState<TimeSlot[]>(initialTimeSlots);
  const [timeError, setTimeError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [getCode, setGetCode] = useState<any>(undefined);
  const [country, setCountry] = useState<any>("");

  const [searchParams, setSearchParams] = useSearchParams();
  const initialSearch = searchParams.get("search") || "";
  const initialPage: any = parseInt(searchParams.get("page") || "1", 10);
  const initialLimit = parseInt(searchParams.get("limit") || "20", 10);
  const [finalValue, setFinalValue] = useState<string>(initialSearch);
  const [pageNumber, setPageNumber] = useState<any>(initialPage);
  const [countData, setCountData] = useState<any>(initialLimit);
  const [passwordModalData, setPasswordMadalData] = useState<any>();
  const [text, setText] = useState<any>();
  const [copied, setCopied] = useState(false);
  const [copied1, setCopied1] = useState(false);

  const navigate = useNavigate();
  const { data: GetKeyHost, isLoading } = useGetKeyHost({
    page: finalValue?.length < 0 ? initialPage : pageNumber,
    limit: countData,
    search: initialSearch,
  });
  const { data: getBoxesData } = useGetBoxes();
  const BoxItems = getBoxesData?.data;

  const { mutateAsync } = useUpdateKeyHostStatus();
  const handlePrevPage = () => {
    setPageNumber((prevPage: any) => prevPage - 1);
  };

  const handleNextPage = () => {
    setPageNumber((nextPage: any) => nextPage + 1);
  };
  const handleRowLimitChange = (newValue: number) => {
    setCountData(newValue);
  };
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFinalValue(e.target.value);
  };
  const handleApplyClick = () => {
    setSearchParams({ search: finalValue, page: initialPage });
    setPageNumber(1);
  };

  const handleCancelClick = () => {
    setSearchParams({ search: "" });
    setFinalValue("");
  };
  const handleClick = (id: any) => {
    navigate("/keyhostdetail", {
      state: {
        id,
      },
    });
  };
  const handleToggleClick = async (id: string, action: number) => {
    let Status = action === 1 ? 2 : 1;
    try {
      const res: any = await mutateAsync({
        id: id,
        status: Status,
      });
      if (res.success) {
        toast.success(res?.message);
      } else {
        console.error("Failed to update user status:", res.error);
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };

  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
    setFieldError,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: keyHostinitialValues,
    validationSchema: keyHostValidationSchema,
    onSubmit: async () => {
      if (modalState.modalType === "Add") {
        if (hasEmptyTimeSlots === true) {
          setErrorMessage("Please fill in all time slots");
        } else {
          try {
            const res: any = await addhostKey({
              user_name: values.username,
              email: values.email,
              country_code: values.countryCode,
              country_name: values?.country,
              phone_number: values.phoneNumber,
              store_name: values.store_name,
              store_info: values.store_info,
              address: values.address,
              latitude: values.lat,
              longitude: values.lng,
              active_time: timeSlots,
              box_id: selectedData,
            });
            if (res?.status === true) {
              toast.success(res?.message);
              handleModalClose();
              setCountry("");
            }
          } catch (error: any) {
            toast.error(error?.response?.data?.message);
          }
        }
      }
      if (modalState.modalType === "Edit") {
        if (hasEmptyTimeSlots === true) {
          setErrorMessage("Please fill in all time slots");
        } else {
          try {
            const res: any = await updateAsync({
              id: modalState?.data?._id,
              user_name: values.username,
              email: values.email,
              country_name: values?.country,
              country_code: values.countryCode,
              phone_number: values.phoneNumber,
              store_name: values.store_name,
              store_info: values.store_info,
              address: values.address,
              latitude: values.lat,
              longitude: values.lng,
              active_time: timeSlots,
              box_id: selectedData,
            });
            if (res?.status === true) {
              toast.success(res?.message);
              handleModalClose();
              setCountry("");
            }
          } catch (error: any) {
            toast.error(error?.response?.data?.message);
          }
        }
      } else {
        return null;
      }
    },
  });

  //ADD KEY HOST
  const handleMergeAndSelect = (BoxItems: any = [], values: any) => {
    const alreadySelected =
      modalState?.data?.box_details?.map((item: any) => item || "") || [];
    const mergedArray = [...BoxItems, ...alreadySelected];
    const uniqueMergedArray = mergedArray.filter(
      (option, index, self) =>
        index === self?.findIndex((o) => o?._id === option._id)
    );
    const selectedData = values.selectedOption.map(
      (option: any) => option?._id
    );
    return { mergedArray: uniqueMergedArray, selectedData };
  };
  const { mergedArray, selectedData } = handleMergeAndSelect(BoxItems, values);

  const hasEmptyTimeSlots = timeSlots?.some(
    (obj: any) =>
      obj.closed === false &&
      (obj.start_time === "" ||
        obj.start_time === null ||
        obj.end_time === "" ||
        obj.end_time === null)
  );

  useEffect(() => {
    setTimeError(hasEmptyTimeSlots);
  }, [timeSlots]);
  const handleErrorClick = () => {
    setTimeError(hasEmptyTimeSlots);
    setErrorMessage(hasEmptyTimeSlots ? "Please fill in all time slots" : "");
  };

  const updateTimeSlot = (
    index: number,
    field: string,
    value: string | boolean
  ) => {
    const newTimeSlots = [...timeSlots];
    newTimeSlots[index] = { ...newTimeSlots[index], [field]: value };
    setTimeSlots(newTimeSlots);
  };
  const handleCountryChange = (newCountry: any) => {
    setCountry(newCountry);
  };

  useEffect(() => {
    if (country) {
      const code = getCountryCallingCode(country);
      setGetCode(code);
      if (code) {
        setFieldValue("countryCode", getCode);
      } else {
        setFieldError("countryCode", "country code is required*");
      }
    }
  }, [country, getCode]);
  const handelPhoneNumber = (newValue: any) => {
    if (newValue) {
      setFieldValue("phoneNumber", newValue);
    } else {
      setFieldError("phoneNumber", "Phone Number is required*");
    }
  };
  const { mutateAsync: addhostKey } = useAddKeyHost();
  const { mutateAsync: updateAsync } = useUpdateKeyHost();

  // const onSelected = (selectedList: any, selectedItem: any) => {

  //   setFieldValue("selectedOption", selectedList);
  // };
  const onSelected = (selectedList: any, selectedItem: any) => {
    const uniqueSelectedOptions = selectedList.filter(
      (option: any, index: number, self: any) =>
        index === self.findIndex((o: any) => o === option)
    );
    setFieldValue("selectedOption", uniqueSelectedOptions);
  };
  const onRemove = (selectedList: any, removeItem: any) => {
    const uniqueSelectedOptions = selectedList.filter(
      (option: any, index: number, self: any) =>
        index === self.findIndex((o: any) => o === option)
    );
    setFieldValue("selectedOption", uniqueSelectedOptions);
  };

  const handleModalOpen = (e: any, data?: any) => {
    if (e.target.title === "Add") {
      if (BoxItems?.length === 0) {
        toast.error("Please Add Box Items");
      } else {
        setModalState({
          ...modalState,
          modalType: e.target.title,
          open: true,
          data: {},
        });
      }
    }
    if (e.target.title === "Edit") {
      setModalState({
        ...modalState,
        modalType: e.target.title,
        open: true,
        data: data,
      });
    }
  };

  const handleModalClose = () => {
    setModalState({
      ...modalState,
      modalType: "",
      open: false,
      data: {},
    });
    resetForm();
    setCountry("");
    setErrorMessage("");
    setTimeSlots(initialTimeSlots);
  };

  useEffect(() => {
    if (modalState?.modalType === "Edit") {
      setValues({
        address: modalState?.data?.address,
        email: modalState?.data?.email,
        lat: modalState?.data?.location?.coordinates?.[1],
        lng: modalState?.data?.location?.coordinates?.[0],
        store_info: modalState?.data.store_info,
        store_name: modalState?.data.store_name,
        username: modalState?.data.user_name,
        countryCode: modalState?.data?.country_code,
        country: modalState?.data?.country_name,
        phoneNumber: JSON.stringify(modalState?.data?.phone_number),
        selectedOption: modalState?.data?.box_details,
      });
      setTimeSlots(
        modalState?.data?.active_times === undefined || null
          ? initialTimeSlots
          : modalState?.data?.active_times
      );
    }
  }, [modalState?.data]);

  const returnModalChild = () => {
    if (modalState.modalType === "Add") {
      return (
        <KeyHostModal
          modalTitle="Add Key Host"
          setFieldValue={setFieldValue}
          values={values}
          handleChange={handleChange}
          handleBlur={handleBlur}
          errors={errors}
          touched={touched}
          country={country}
          handleCountryChange={handleCountryChange}
          handelPhoneNumber={handelPhoneNumber}
          timeSlots={timeSlots}
          setTimeSlots={setTimeSlots}
          handleSubmit={handleSubmit}
          updateTimeSlot={updateTimeSlot}
          BoxItems={BoxItems}
          handleErrorClick={handleErrorClick}
          timeError={timeError}
          errorMessage={errorMessage}
          selectedOptions={values?.selectedOption}
          onSelected={onSelected}
          onRemove={onRemove}
          isOpen={modalState.open}
          handleModalClose={handleModalClose}
        />
      );
    }
    if (modalState.modalType === "Edit") {
      return (
        <KeyHostModal
          modalTitle="Edit Key Host "
          setFieldValue={setFieldValue}
          values={values}
          handleChange={handleChange}
          handleBlur={handleBlur}
          errors={errors}
          touched={touched}
          country={country}
          handleCountryChange={handleCountryChange}
          handelPhoneNumber={handelPhoneNumber}
          timeSlots={timeSlots}
          setTimeSlots={setTimeSlots}
          handleSubmit={handleSubmit}
          updateTimeSlot={updateTimeSlot}
          handleErrorClick={handleErrorClick}
          timeError={timeError}
          errorMessage={errorMessage}
          BoxItems={mergedArray}
          selectedOptions={values?.selectedOption}
          onSelected={onSelected}
          onRemove={onRemove}
          isOpen={modalState.open}
          handleModalClose={handleModalClose}
        />
      );
    } else return null;
  };

  //resend password
  const { mutateAsync: resendPass } = useResendPassword();
  const handleModal = (id: any) => {
    const dataItem = GetKeyHost?.data?.keyHosts?.find(
      (data: any) => data._id === id
    );
    if (dataItem) {
      setPasswordMadalData(dataItem);
      setText(dataItem?.email);
    }
  };
  const resendSubmit = async () => {
    try {
      const res: any = await resendPass({
        email: passwordModalData?.email,
      });
      if (res?.status === true) {
        toast.success(res?.message);
        const closeModelButton: any = document.getElementById("close-btn");
        closeModelButton.click();
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };

  const handleCopy = (text: string, setCopiedFunc: Function) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopiedFunc(true);
        setTimeout(() => {
          setCopiedFunc(false);
        }, 2000);
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };
  // get name from country code
  const callingCodeToCountry: any = {};
  getCountries().forEach(
    (country) =>
      (callingCodeToCountry[getCountryCallingCode(country)] = country)
  );
  const getCountryNameFromCallingCode = (callingCode: any, labels: any) =>
    callingCodeToCountry[callingCode] || null;

  const handleConvertCallingCode = (callingCode: any) =>
    getCountryNameFromCallingCode(callingCode, en);

  useEffect(() => {
    if (modalState?.data?.country_code) {
      const CCode = handleConvertCallingCode(modalState?.data?.country_code);
      setCountry(CCode);
    }
  }, [modalState?.data?.country_code]);
  //
  return (
    <Fragment>
      <div className="main-wrapper">
        <Header title="Hosts" />
        <CustomLaoder loader={isLoading}>
          <div className="content-wrapper">
            <section className="main-section coach-section spacer">
              <div className="container">
                <div className="wrapper-card">
                  <div className="row gy-3">
                    <div className="col-xl-12">
                      <form>
                        <div className="row justify-content-between g-3">
                          <div className="col-lg-8 col-xl-8 col-xxl-6">
                            <div className="row align-items-center gy-3">
                              <div className="col-sm-7 col-xl-7">
                                <div className="form-block p-relative">
                                  <input
                                    type="search"
                                    className="form-control"
                                    id="search"
                                    placeholder="Search by Name or Email"
                                    value={finalValue}
                                    onChange={handleSearchChange}
                                    // value={searchValue}
                                    // onChange={handleSearchChange}
                                  />
                                  <span className="search-icon" />
                                </div>
                              </div>
                              <div className="col-12 col-sm-5 col-xl-5">
                                <button
                                  type="submit"
                                  className="btn primary-btn"
                                  onClick={handleApplyClick}>
                                  Apply
                                </button>
                                <button
                                  type="submit"
                                  className="btn secondary-btn"
                                  onClick={handleCancelClick}>
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="col-xl-12">
                      <div className="sub-heading flex-box">
                        <h2>Key Host Listing</h2>
                        <button
                          onClick={(e: any) => {
                            handleModalOpen(e);
                          }}
                          title="Add"
                          className="btn primary-btn me-0">
                          + Add Host
                        </button>
                      </div>
                      <div className="table-blk table-responsive">
                        <table id="myTable" className="display border-0">
                          <thead>
                            <tr>
                              <th>Name </th>
                              {/* <th>Email</th> */}
                              <th>Store </th>
                              <th>Total Key Hosted</th>
                              <th>Key Host Location</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {GetKeyHost?.data?.keyHosts?.map(
                              (data: any, index: any) => (
                                <tr key={index}>
                                  <td>
                                    <span className="table-profile">
                                      <span className="table-profileimg">
                                        <img
                                          src="assets/images/icons/dummy-user.png"
                                          alt="user"
                                        />
                                      </span>
                                      <span className="table-username">
                                        {data.user_name}
                                      </span>
                                    </span>
                                  </td>
                                  {/* <td>{data?.email}</td> */}
                                  <td>{data?.store_name}</td>
                                  <td>{data.total_keys}</td>
                                  <td>{data?.address}</td>
                                  <td>
                                    <span
                                      className={`c-pill 
                                      ${
                                        data.status === 1
                                          ? "c-pill--success"
                                          : "c-pill--danger"
                                      }`}>
                                      {data.status === 1
                                        ? "Active"
                                        : "Suspended"}
                                    </span>
                                    {/* <span
                                    className={`
                                      ${
                                        data.status === 1
                                          ? "table-status st-active"
                                          : "table-status st-suspended"
                                      } px-2`}>
                                    {data.status === 1 ? "Active" : "Suspended"}
                                  </span> */}
                                  </td>
                                  <td>
                                    <span className="table-action">
                                      <i
                                        className="fa-solid fa-rotate-right"
                                        style={{ color: "#5aa8a4" }}
                                        data-bs-toggle="modal"
                                        data-bs-target="#resendModal"
                                        onClick={() =>
                                          handleModal(data._id)
                                        }></i>

                                      <a
                                        onClick={() => handleClick(data?._id)}
                                        className="table-eye">
                                        <EyeSvg />
                                      </a>
                                      <span
                                        className="table-check"
                                        key={data._id}>
                                        <input
                                          type="checkbox"
                                          id={`toggleEye${data._id}`}
                                          onChange={() =>
                                            handleToggleClick(
                                              data._id,
                                              data?.status
                                            )
                                          }
                                          checked={
                                            data?.status === 1 ? true : false
                                          }
                                        />
                                        <label
                                          htmlFor={`toggleEye${data._id}`}
                                        />
                                      </span>
                                      <img
                                        title="Edit"
                                        onClick={(e: any) => {
                                          handleModalOpen(e, data);
                                        }}
                                        src="./assets/images/icons/edit_icon.svg"
                                      />
                                    </span>
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                        {GetKeyHost?.data?.keyHosts?.length === 0 ? (
                          <div className="d-flex justify-content-center align-items-center my-3 sub-heading">
                            <h2 className="m-0">No data found</h2>
                          </div>
                        ) : null}
                      </div>
                      <Pagination
                        totalPages={GetKeyHost?.data?.totalPage}
                        currentPage={pageNumber}
                        handlePrevPage={handlePrevPage}
                        handleNextPage={handleNextPage}
                        handleRowLimitChange={handleRowLimitChange}
                      />
                    </div>
                    {/* <button onClick={() => navigate("/addKeyHost")}>
                    Add key host
                  </button> */}
                  </div>
                </div>
              </div>
            </section>
            <div
              className="modal fade common-modal"
              id="resendModal"
              tabIndex={-1}
              aria-labelledby="resendPassword"
              aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-body">
                    <button
                      id="close-btn"
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                    <div className="common-box-body">
                      <h2>Resend Password</h2>

                      <div className="common-modal-form">
                        <div className="common-modal-field">
                          <div className="common-modal-field">
                            <div className="d-flex justify-content-between">
                              <label>Key Host Email</label>
                              {!copied ? (
                                <i
                                  className="fa-regular fa-copy"
                                  style={{
                                    cursor: "pointer",
                                    color: "#72bcb8",
                                  }}
                                  onClick={() =>
                                    handleCopy(
                                      passwordModalData?.email,
                                      setCopied
                                    )
                                  }
                                />
                              ) : (
                                <span style={{ color: "#72bcb8" }}>
                                  Copied!
                                </span>
                              )}
                            </div>

                            <input
                              placeholder="email"
                              className="form-control"
                              type="text"
                              value={passwordModalData?.email || ""}
                              readOnly
                            />
                          </div>
                          <div className="common-modal-field">
                            <div className="d-flex justify-content-between">
                              <label>Key Host Password</label>
                              {!copied1 ? (
                                <i
                                  className="fa-regular fa-copy"
                                  style={{
                                    cursor: "pointer",
                                    color: "#72bcb8",
                                  }}
                                  onClick={() =>
                                    handleCopy(
                                      passwordModalData?.password,
                                      setCopied1
                                    )
                                  }
                                />
                              ) : (
                                <span style={{ color: "#72bcb8" }}>
                                  Copied!
                                </span>
                              )}
                            </div>
                            <input
                              placeholder="email"
                              className="form-control"
                              type="text"
                              value={passwordModalData?.password || ""}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                      <div className="common-modal-btns">
                        <button
                          className="btn primary-btn me-0"
                          onClick={() => resendSubmit()}>
                          Resend
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CustomLaoder>
      </div>
      {returnModalChild()}
    </Fragment>
  );
};

export default KeyHostListing;
