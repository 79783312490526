const NoRecordFound = () => {
  return (
    // <div className="empty-state">
    <div className="empty-state__content">
      <div className="empty-state__icon">
        <img src="assets/images/logo/no-record.svg" alt="" />
      </div>
      <div className="empty-state__message">
        No records have been added yet.
      </div>
    </div>
    // </div>
  );
};

export default NoRecordFound;
