import { useMutation, useQueryClient } from "react-query";
import { Auth } from "../../interface";
import { mutatePostFn } from "../../services/networking.service";
import ENDPOINTS from "../../services/endPoints";

interface Payload {
  email: string;
  password: string;
  fcmKey: string;
}

export const useUserLogin = () => {
  const queryClient = useQueryClient();
  return useMutation<Auth, never, Payload>(
    (data) => mutatePostFn(ENDPOINTS.OWNER_ACCOUNT_LOGIN, data),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries([]);
      },
    }
  );
};
